import _ from 'lodash';
import TumourType from './TumourType';
import IMechanism from '../interfaces/Mechanism';

export default {
  // Get all associated areas.
  GetAll: (): IMechanism[] => {
    const mechanisms: IMechanism[] = [];
    const tumorTypes = TumourType.GetAll();
    const tempTargets: any = {};
    tumorTypes.forEach((tumorType) => {
      const targetCompounds = TumourType.GetTargetCompounds(tumorType.id);
      targetCompounds.forEach((targetCompound) => {
        if (
          targetCompound.field_associated_area
          && targetCompound.field_areas_under_investigation.length
        ) {
          const areaId = targetCompound.field_associated_area.id;
          const index = mechanisms.findIndex(
            (item) => Number(item.id) === Number(targetCompound.field_associated_area.id),
          );
          if (index < 0) {
            const tempObj = {
              field_targets_compounds: [targetCompound],
            };
            mechanisms.push({
              ...targetCompound.field_associated_area,
              ...tempObj,
            });
          } else if (!tempTargets[areaId] || tempTargets[areaId].indexOf(targetCompound.id) < 0) {
            mechanisms[index].field_targets_compounds.push(targetCompound);
          }

          if (!tempTargets[areaId]) {
            tempTargets[areaId] = [];
          }
          tempTargets[areaId].push(targetCompound.id);
        }
      });
    });

    return _.orderBy(mechanisms, ['order'], ['asc']);
  },
};
