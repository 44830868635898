import React, { MouseEvent, useState } from 'react';
import { NavLink } from 'react-router-dom';
import App from '../../../store/App';
import ItemSearch from './ItemSearch';

const MainNavigation = () => {
  // Bar class state.
  const [barClass, setBarClass] = useState('');

  /**
   * Show/hide nav for mobile devices.
   */
  function toggleNav() {
    setBarClass(barClass ? '' : 'toggle');
    document.querySelector('nav.navbar')!.classList.toggle('open');
    document.querySelector('body')!.classList.toggle('nav-overflow-hidden');
  }

  /**
   * Handle click on nav link.
   */
  const handleNavClick = () => {
    if (barClass) {
      toggleNav();
    }
  };

  /**
   * Handle click on mobile nav switcher.
   *
   * @param event
   */
  function barIconClick(event: MouseEvent) {
    event.preventDefault();
    toggleNav();
  }

  const navLinks = App.GetMainNavigation();
  return (
    <>
      <div className="menu">
        <ul className="nav">
          <li>
            <NavLink to="/" exact activeClassName="active" onClick={handleNavClick}>Home</NavLink>
          </li>
          { navLinks.map((link, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={index}>
              <NavLink to={link.url} activeClassName="active" onClick={handleNavClick}>{ link.label }</NavLink>
            </li>
          )) }
        </ul>
      </div>
      {barClass.length === 0 && <ItemSearch />}
      <div className={`bar-icon ${barClass}`} onClick={barIconClick}>
        <div className="line1" />
        <div className="line2" />
        <div className="line3" />
      </div>
    </>
  );
};

export default MainNavigation;
