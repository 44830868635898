import React, { ChangeEvent, FormEvent } from 'react';
import './Login.css';
import fetch from '../../../store/Api';
import IApp from '../../../interfaces/App';
import Internal from '../../../store/Internal';

interface IProps {
  // eslint-disable-next-line no-unused-vars
  onSuccess: (app: IApp) => void, // Callback on success login.
}

interface IState {
  password: string, // Entered user password.
  error: string, // Validation message.
  submitted: boolean // Is form submitted or not.
}

class Login extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      password: '',
      error: '',
      submitted: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  /**
   * Handle password input change event.
   *
   * @param event
   */
  handleChange(event: ChangeEvent) {
    const { target } = event;
    const inputTarget = target as HTMLInputElement;
    this.setState({
      password: inputTarget.value,
    });
  }

  /**
   * Handle form submit event.
   *
   * @param event
   */
  handleSubmit(event: FormEvent) {
    event.preventDefault();
    this.setState({
      submitted: true,
    });
    const { onSuccess } = this.props;
    const { password } = this.state;
    fetch(password).then((result) => {
      onSuccess(result.data.data);
      Internal.setPassword(password);
    }).catch((error) => {
      this.setState({
        error: 'Invalid password',
      });
      console.error(error);
    }).finally(() => {
      this.setState({
        submitted: false,
      });
    });
  }

  render() {
    const { error, submitted } = this.state;
    return (
      <div className="login-modal custom-modal is-show">
        <div className="wrapper">
          <div className="content">
            <form className="login-form" onSubmit={this.handleSubmit}>
              <div className="content-body">
                <div className="row">
                  <div className="col-12">
                    <label htmlFor="login_password">
                      <span className="label">Password</span>
                      <input type="password" name="password" id="login_password" className={error ? 'error' : ''} onChange={this.handleChange} />
                      <span className="error">{ error }</span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="content-footer">
                <button type="submit" className="btn btn-theme btn-full" disabled={submitted} onClick={this.handleSubmit}>Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
