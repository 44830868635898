import React from 'react';
import IAbbreviation from '../../../../../interfaces/Abbreviation';

interface IProps {
  character: string, // Character for group.
  abbreviations: IAbbreviation[] // Abbreviation within a group.
}

function AbbreviationGroup(props: IProps) {
  const { character, abbreviations } = props;

  return (
    <div id={character} className="filter-scroll-content">
      { abbreviations.map((abbreviation) => (
        <div className="glosssry-desc" key={abbreviation.id}>
          <div className="title">{ abbreviation.label }</div>
          <div className="desc">{ abbreviation.field_definition }</div>
        </div>
      )) }
    </div>
  );
}

export default AbbreviationGroup;
