import React, { RefObject } from 'react';

interface IProps {
  src: string, // Video src.
}

interface IState {}

class Video extends React.Component<IProps, IState> {
  private readonly videoWrapperRef: RefObject<any>;

  private readonly videoRef: RefObject<any>;

  private readonly playButtonRef: RefObject<any>;

  constructor(props: IProps) {
    super(props);
    this.videoWrapperRef = React.createRef();
    this.videoRef = React.createRef();
    this.playButtonRef = React.createRef();
    this.handlePlay = this.handlePlay.bind(this);
    this.handlePause = this.handlePause.bind(this);
  }

  handlePlay() {
    this.videoRef.current.play();
    this.playButtonRef.current.classList.add('is-hidden');
    this.videoRef.current.classList.remove('has-media-controls-hidden');
    this.videoRef.current.setAttribute('controls', 'controls');
  }

  handlePause() {
    this.playButtonRef.current.classList.remove('is-hidden');
    this.videoRef.current.classList.add('has-media-controls-hidden');
  }

  render() {
    const { src } = this.props;

    return (
      <div ref={this.videoWrapperRef} className="embed-container embed-play-action content-grid-block">
        <video
          ref={this.videoRef}
          className="embed-video"
          controls
          controlsList="nodownload"
          onPlay={this.handlePlay}
          onPause={this.handlePause}
        >
          <source src={src} type="video/mp4" />
        </video>
        <div ref={this.playButtonRef} className="video-overlay-play-button" onClick={this.handlePlay}>
          <svg width="32" height="41" viewBox="0 0 32 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.20002 39.4L30.2 23.4C30.5908 23.0516 30.9278 22.6472 31.2 22.2C31.5153 21.7713 31.74 21.283 31.8607 20.7648C31.9813 20.2466 31.9953 19.7092 31.9017 19.1854C31.8082 18.6616 31.6091 18.1623 31.3166 17.7178C31.024 17.2734 30.6441 16.8931 30.2 16.6L6.20002 0.599998C5.58804 0.224731 4.88773 0.0179195 4.17006 0.000524486C3.45239 -0.0168705 2.74289 0.155771 2.11344 0.50095C1.484 0.846128 0.957 1.35157 0.585849 1.96606C0.214698 2.58055 0.0125956 3.28223 1.85882e-05 4V36C-0.015841 36.7758 0.192489 37.5397 0.600017 38.2C0.881336 38.655 1.25174 39.0484 1.68895 39.3567C2.12616 39.6649 2.62117 39.8815 3.14424 39.9936C3.66731 40.1057 4.20763 40.1109 4.73276 40.0089C5.25788 39.9069 5.75696 39.6998 6.20002 39.4Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
    );
  }
}

export default Video;
