import React, { useEffect } from 'react';
import bgImage from '../../../assets/images/type-bg-4.webp';
import Abbreviation from '../../../store/Abbreviation';
// import PopularTerms from './Partials/PopularTerms/PopularTerms';
import ScrollingHeader from './Partials/ScrollingHeader/ScrollingHeader';
import AbbreviationGroup from './Partials/AbbreviationGroup/AbbreviationGroup';
import getSiteName from '../../../helpers/Common';
import MainContentWrapper from '../../Shared/MainContenWrapper/MainContentWrapper';

function Glossary() {
  useEffect(() => {
    document.title = `Glossary | ${getSiteName()}`;
  }, []);

  const mainStyle = {
    backgroundImage: `url(${bgImage})`,
  };

  const abbreviations = Abbreviation.GetAll();
  const chars: string[] = [];
  const groupedAbbreviations: any = {};

  abbreviations.forEach((abbreviation) => {
    const char = abbreviation.label.charAt(0).toUpperCase();
    if (chars.indexOf(char) < 0) {
      chars.push(char);
    }
    if (!groupedAbbreviations[char]) {
      groupedAbbreviations[char] = [];
    }
    groupedAbbreviations[char].push(abbreviation);
  });

  return (
    <MainContentWrapper wrapperClass="bg-wrapper is-bg-ani is-bg-right page-ani-fade" wrapperStyle={mainStyle} animateBodyImg>
      <div className="inner-container">
        <div className="container">
          <div className="title-box">
            <div className="bg-content-block bg-w-auto">
              <h1><strong>Glossary</strong></h1>
              {/* <p>Popular glossary teams</p> */}
            </div>
          </div>
          <div className="clear" />
        </div>
        <div className="glossary-block">
          <div className="container">
            {/* <PopularTerms /> */}
            <div className="filter-content-block">
              <ScrollingHeader chars={chars} />
              <div className="filter-content">
                { Object.keys(groupedAbbreviations).map((char) => (
                  <AbbreviationGroup
                    character={char}
                    abbreviations={groupedAbbreviations[char]}
                    key={char}
                  />
                )) }
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainContentWrapper>
  );
}

export default Glossary;
