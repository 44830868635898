import React from 'react';
import { Link } from 'react-router-dom';
import ITargetCompound from '../../../../../interfaces/TargetCompound';
import AreaUnderInvestigationRow from '../AreaUnderInvestigationRow/AreaUnderInvestigationRow';
import TargetCompound from '../../../../../store/TargetCompound';
import IAreaUnderInvestigationArray from '../../../../../interfaces/AreaUnderInvestigationArray';
import IAreaUnderInvestigation from '../../../../../interfaces/AreaUnderInvestigation';
import { getWindowPathName } from '../../../../../helpers/Common';

interface IProps {
  targetCompound: ITargetCompound, // Target/compound.
  indexValue: number,
  phaseStyle: object
}

function TargetCompoundItem(props: IProps) {
  const { targetCompound: targetComp, indexValue, phaseStyle } = props;
  const tumourType = TargetCompound.GetTumourTypeByTargetCompoundId(Number(targetComp.id));
  const fieldAreas = targetComp.field_areas_under_investigation;

  if (!tumourType) {
    return (<div />);
  }

  const rows = fieldAreas.reduce((row: IAreaUnderInvestigationArray, key, index) => {
    const arrayData = (index % 2 === 0 ? row.push([key]) : row[row.length - 1].push(key)) && row;
    return arrayData;
  }, []);

  return (
    <div className={(indexValue === 1) ? 'table-row-bg' : 'table-row-bg bg-dark'}>
      { rows.map((area: IAreaUnderInvestigation, index:number) => (
        <>
          { index === 0 && tumourType && (
            <div className="table-row-header">
              <div className="row-title">{targetComp.field_compound_name}</div>
              <div className="action">
                <Link
                  to={{ pathname: `/scientific-pillars/type/${tumourType.id}/target/${targetComp.id}`, state: { prevPath: getWindowPathName() } }}
                  className="target-info"
                >
                  <span>
                    View
                    <strong>{targetComp.label}</strong>
                  </span>
                  <em className="az-icon icon-arrow-right-white" />
                </Link>
              </div>
            </div>
          )}
          <AreaUnderInvestigationRow
            areaUnderInvestigation={area}
            key={area.id}
            indexValue={index % 2 === 0 ? 1 : 0}
            phaseStyle={phaseStyle}
          />
        </>
      ))}
    </div>
  );
}

export default TargetCompoundItem;
