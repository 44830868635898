import React from 'react';
import { Link } from 'react-router-dom';

interface IProps {
    title: string,
    backUrl?: string,
    description?: string
}

function Info(props: IProps) {
  const { title, backUrl, description } = props;

  return (
    <div className="type-innerpage-title">
      { !!backUrl && (
        <div className="back-action">
          <Link to={backUrl} className="btn-back">
            <em className="az-icon icon-arrow-left" />
            BACK
          </Link>
        </div>
      )}
      <div className="bg-content-block">
        <h1><strong>{title}</strong></h1>

        { !!description && (
          <div dangerouslySetInnerHTML={{ __html: description }} />
        )}
      </div>
    </div>
  );
}

Info.defaultProps = {
  backUrl: '',
  description: '',
};

export default Info;
