/**
 * Convert phase number into css class.
 *
 * @param phaseNumber
 */
function phaseCssClass(phaseNumber: string): string {
  switch (phaseNumber) {
    case 'I':
      return 'phase-one';
    case 'II':
      return 'phase-two';
    case 'III':
      return 'phase-three';
    case 'IV':
      return 'phase-four';
    case 'Ib/IIa':
      return 'phase-two';
    default:
      return 'phase';
  }
}

export default phaseCssClass;
