import React from 'react';
import IReference from '../../../../../../interfaces/Reference';

interface IProps {
  references: IReference[] // References.
}

function References(props: IProps) {
  const { references } = props;
  const refsCount = references.length;

  /**
   * Remove HTML tags in the string.
   * @param str
   */
  function replaceTags(str: string) {
    return str.replace('<p>', '').replace('</p>', '');
  }

  /**
    * Handle Continue button click event.
    */
  /* const handleContinueClick = (e: any, refUrl: string) => {
    e.preventDefault();
    window.open(refUrl, '_system', 'location=yes');
  }; */

  return (
    <>
      <br />
      <h2 className="title">References</h2>
      <div className="action-link">
        { references.map((reference, i) => (
          <React.Fragment key={reference.id}>
            { reference.field_url && (
              <a
                href={reference.field_url}
                className="exclude-exit-ramp-with-popup"
              >
                <span dangerouslySetInnerHTML={{ __html: `${i + 1}. ${replaceTags(reference.title)}` }} />
              </a>
            )}
            { !reference.field_url && (
              <span dangerouslySetInnerHTML={{ __html: `${i + 1}. ${replaceTags(reference.title)}` }} />
            )}
            { i < refsCount - 1 && <br /> }
          </React.Fragment>
        )) }
      </div>
    </>
  );
}

export default References;
