import { useContext } from 'react';
import IApp from '../interfaces/App';
import AppContext from '../context/AppContext';
import TargetCompound from './TargetCompound';
import IDDR from '../interfaces/DDR';
import IDDRHotspot from '../interfaces/DDRHotspot';

export default {
  // Get all DDRs.
  GetAll: (): IDDR[] => {
    const data: IApp = useContext(AppContext);

    if (!data || !data.ddr_overview) {
      return [];
    }

    return data.ddr_overview.map((ddr) => {
      const hotspots: IDDRHotspot[] = [];
      ddr.hotspots.forEach((hotspot) => {
        if (hotspot.tcId) {
          const tcid = Number(hotspot.tcId);
          const tumourType = TargetCompound.GetTumourTypeByTargetCompoundId(Number(tcid));
          if (tumourType) {
            // Assign target/compound object to hotspot
            const targetCompound = TargetCompound.GetById(Number(tumourType.id), tcid);
            if (targetCompound) {
              const point = { ...hotspot, target_compound: targetCompound };
              hotspots.push(point);
            }
          }
        } else {
          hotspots.push(hotspot);
        }
      });
      return { ...ddr, hotspots };
    });
  },
};
