import React, { useEffect } from 'react';

interface IProps {
  url: string,
  onClosed: () => void, // Sidebar closed callback.
}

function IFrameSidebar(props: IProps) {
  const { url } = props;

  /**
   * Open sidebar.
   */
  function openSidebar() {
    // Use timeout to make animation.
    setTimeout(() => {
      document.body.classList.add('iframe-overflow-hidden');
      const popup = document.querySelector('.iframe-popup-sidebar');
      if (popup) {
        popup.classList.add('open');
      }
      const overlay = document.querySelector('.iframe-popup-sidebar-overlay');
      if (overlay) {
        overlay.classList.add('active');
      }
    }, 1);
  }

  /**
   * Close sidebar.
   */
  function closeSidebar() {
    const { onClosed } = props;
    document.body.classList.remove('iframe-overflow-hidden');
    const popup = document.querySelector('.iframe-popup-sidebar');
    if (popup) {
      popup.classList.remove('open');
    }
    const overlay = document.querySelector('.iframe-popup-sidebar-overlay');
    if (overlay) {
      overlay.classList.remove('active');
    }

    setTimeout(() => onClosed(), 300);
  }

  useEffect(() => {
    openSidebar();

    return () => {
      document.body.classList.remove('iframe-overflow-hidden');
    };
  }, []);

  return (
    <>
      <div className="iframe-popup-sidebar">
        <div className="sidebar-content-wrapper">
          <div className="iframe-top-container">
            <button type="button" className="bar-icon close-iframe-popup" aria-label="Close" onClick={closeSidebar}>
              <div className="line1" />
              <div className="line2" />
              <div className="line3" />
            </button>
          </div>
          <div className="iframe-container">
            {/* eslint-disable-next-line */}
            <iframe src={url} width="100%" height="100%" frameBorder="0" allowFullScreen />
          </div>
        </div>
      </div>
      <div className="iframe-popup-sidebar-overlay" />
    </>
  );
}

export default IFrameSidebar;
