import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import App from './components/App';
import './assets/css/main.css';
import './assets/css/print.css';
import { isOfflineApp } from './helpers/Common';

const base = window.location.pathname.substr(0, 6) === '/draft' ? '/draft' : '/';
const appRenderer = () => {
  const isOffline = isOfflineApp();
  return (
    <>
      {isOffline ? (
        <HashRouter basename={base}>
          <App />
        </HashRouter>
      ) : (
        <BrowserRouter basename={base}>
          <App />
        </BrowserRouter>
      )}
    </>
  );
};

ReactDOM.render(
  <>
    {appRenderer()}
  </>,
  document.getElementById('root'),
);
