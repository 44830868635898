import React from 'react';
import IMechanism from '../../../../../interfaces/Mechanism';

interface IProps {
  mechanism: IMechanism
}

function MechanismInfo(props: IProps) {
  const { mechanism } = props;
  const body = mechanism.body ? mechanism.body.replace('\n', '<br /><br />') : '';
  return (
    <div className="content-block">
      <div className="row">
        <div className="col-12 col-md-4 col-lg-2 col-xl-2">
          <div className="img-block">
            <img src={mechanism.field_icon} alt={mechanism.label} />
          </div>
        </div>
        <div className="col-12 col-md-8 col-lg-5 col-xl-6" dangerouslySetInnerHTML={{ __html: body }} />
        <div className="col-12 col-md-12 col-lg-5 col-xl-4">
          { mechanism.field_video_file && (
            <div className="video-wrapper">
              <div className="embed-container">
                <video className="embed-block" controls>
                  <source src={mechanism.field_video_file} type="video/mp4" />
                </video>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default MechanismInfo;
