import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import Brief from './Partials/Brief/Brief';
import Tabs from './Partials/Tabs/Tabs';
import TargetCompoundStore from '../../../store/TargetCompound';
import Overview from './Partials/Overview/Overview';
import NotFound from '../NotFound/NotFound';
import Abbreviations from './Partials/Abbreviations/Abbreviations';
import ClinicalTrials from './Partials/ClinicalTrials/ClinicalTrials';
import getSiteName from '../../../helpers/Common';
import TumourType from '../../../store/TumourType';
import MainContentWrapper from '../../Shared/MainContenWrapper/MainContentWrapper';

function TargetCompound() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const defaultTab = query.get('tab') || (window.innerWidth >= 767 ? 'overview' : '');
  const [activeTab, setActiveTab] = useState(defaultTab);
  const { ttid, tcid } = useParams<{ ttid: string, tcid: string }>();
  const tumourTypeId = Number(ttid);
  const targetCompoundId = Number(tcid);
  const tumourType = TumourType.GetById(tumourTypeId);
  const targetCompound = TargetCompoundStore.GetById(tumourTypeId, targetCompoundId);
  const locationState: any = location.state;
  let showAOI = location && location.pathname.indexOf('/scientific-pillars') !== 0;
  let backLink = locationState && locationState.prevPath ? locationState.prevPath : `/tumour-type/${tumourTypeId}`;
  if (backLink.substr(0, 6) === '/draft') {
    backLink = backLink.substr(6);
  }
  if (locationState && locationState.prevPath) {
    if (backLink.substr(0, 1) === '#') {
      backLink = backLink.substr(1);
    }
  }
  const activePhases = locationState
    && locationState.selectedPhase ? locationState.selectedPhase.slice() : [];

  if (locationState && locationState.prevPath && locationState.prevPath.indexOf('/cell-pathway') === 0) {
    showAOI = false;
  }

  if (locationState && (locationState.activeDDRId || locationState.selectedPhase)) {
    backLink = {
      pathname: backLink,
      state: {
        activeDDRId: locationState.activeDDRId,
        activePhase: locationState.selectedPhase && locationState.selectedPhase.slice(),
      },
    };
  }

  if (!tumourType || !targetCompound) {
    return (<NotFound />);
  }

  const handleResize = () => {
    if (window.innerWidth >= 767 && activeTab === '') {
      setActiveTab('overview');
    }
  };

  useEffect(() => {
    document.title = `${tumourType.label} | ${targetCompound.label} | ${getSiteName()}`;
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const mainStyle = targetCompound.field_target_image ? {
    backgroundImage: `url(${targetCompound.field_target_image})`,
  } : {};

  const tabClicked = (tab: string) => {
    const newTab = tab === activeTab ? '' : tab;
    setActiveTab(newTab);
  };

  let activeAOI: number[] = [];
  if (showAOI) {
    activeAOI = tumourType.field_areas_under_investigation
      ? tumourType.field_areas_under_investigation.map((aoi) => Number(aoi.id)) : [];
  }

  useEffect(() => {
    if (defaultTab !== activeTab) {
      setActiveTab(defaultTab);
    }
  }, [location]);

  return (
    <MainContentWrapper wrapperClass="bg-tab-wrapper page-ani-fade" wrapperStyle={mainStyle}>
      <div className="inner-container">
        <div className="container">
          <Brief
            backLink={backLink}
            targetCompound={targetCompound}
          />
          <div className="clear" />
        </div>
        <div className="custom-tab-block">
          <Tabs
            activeTab={activeTab}
            onTabClick={(tab) => setActiveTab(tab)}
          />
        </div>
        <div className="tab-content-block">
          <Overview
            targetCompound={targetCompound}
            defaultTab={false}
            active={activeTab === 'overview'}
            onSelected={() => tabClicked('overview')}
          />
          <ClinicalTrials
            targetCompound={targetCompound}
            activeAOI={activeAOI}
            activePhases={activePhases}
            active={activeTab === 'clinical-trials'}
            onSelected={() => tabClicked('clinical-trials')}
          />
          <Abbreviations
            targetCompound={targetCompound}
            active={activeTab === 'abbreviations'}
            onSelected={() => tabClicked('abbreviations')}
          />
        </div>
      </div>
    </MainContentWrapper>
  );
}

export default TargetCompound;
