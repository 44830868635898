import React, { Fragment } from 'react';

interface IProps {
  title: string, // Block title.
  media: string[], // Images and videos.
  footnote: string // Block footnote
}

function MechanismDiseaseAction(props: IProps) {
  function truncateQueryParam(url: string) {
    let curUrl = url;
    if (url && url.includes('?')) {
      /* eslint-disable prefer-destructuring */
      curUrl = url.split('?')[0];
    }
    return curUrl;
  }

  /**
   * Determine whether the URL is an image or not.
   *
   * @param url
   */
  function isImage(url: string) {
    const newUrl = truncateQueryParam(url);
    return newUrl.toLowerCase().match(/\.(jpeg|jpg|gif|png|svg)$/) !== null;
  }

  /**
   * Determine whether the URL is video or not.
   *
   * @param url
   */
  function isVideo(url: string) {
    const newUrl = truncateQueryParam(url);
    return newUrl.toLowerCase().match(/\.(mp4)$/) !== null;
  }

  const { title, media, footnote } = props;

  return (
    <>
      <br />
      <h3>
        <strong>{ title }</strong>
      </h3>
      <div className="grid-row">
        { media.map((url) => (
          <Fragment key={url}>
            { isImage(url) && (
              <div className="grid-box" key={url}>
                <img src={url} alt={title} />
              </div>
            )}
            { isVideo(url) && (
              <div className="grid-box" key={url}>
                <div className="embed-container">
                  <video className="embed-block" controls>
                    <source src={url} type="video/mp4" />
                  </video>
                </div>
              </div>
            )}
          </Fragment>
        )) }
      </div>
      { footnote && <div dangerouslySetInnerHTML={{ __html: footnote }} /> }
    </>
  );
}

export default MechanismDiseaseAction;
