import _ from 'lodash';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import ITargetCompound from '../../../interfaces/TargetCompound';
import TargetCompound from '../../../store/TargetCompound';
import { getWindowPathName } from '../../../helpers/Common';

interface IProps {
  targetCompound: ITargetCompound, // Target/compound.
  activeDDRId?: number,
  onClosed: () => void, // Sidebar closed callback.
}

function TargetCompoundSidebar(props: IProps) {
  const { targetCompound, activeDDRId } = props;
  const tumourType = TargetCompound.GetTumourTypeByTargetCompoundId(Number(targetCompound.id));

  function onBlurLastElement() {
    const firstFocusedEle = document.getElementById('closeBtn');
    if (firstFocusedEle) {
      firstFocusedEle.focus();
    }
  }

  function onBlurSPLink() {
    const takeMeBtn = document.getElementById('takemebtn');
    if (takeMeBtn) {
      takeMeBtn.focus();
      return;
    }
    onBlurLastElement();
  }

  /**
   * Open sidebar.
   */
  function openSidebar() {
    // Use timeout to make animation.
    setTimeout(() => {
      document.body.classList.add('sidebar-overflow-hidden');
      const popup = document.querySelector('.popup-sidebar');
      if (popup) {
        popup.classList.add('open');
      }
      const overlay = document.querySelector('.popup-sidebar-overlay');
      if (overlay) {
        overlay.classList.add('active');
      }

      onBlurLastElement();
    }, 1);
  }

  /**
   * Close sidebar.
   */
  function closeSidebar() {
    const { onClosed } = props;
    document.body.classList.remove('overflow-hidden');
    const popup = document.querySelector('.popup-sidebar');
    if (popup) {
      popup.classList.remove('open');
    }
    const overlay = document.querySelector('.popup-sidebar-overlay');
    if (overlay) {
      overlay.classList.remove('active');
    }

    setTimeout(() => onClosed(), 300);
  }

  useEffect(() => {
    openSidebar();

    return () => {
      document.body.classList.remove('sidebar-overflow-hidden');
    };
  });

  return (
    <>
      <div className="popup-sidebar">
        <div className="sidebar-content-wrapper">
          {/* eslint-disable-next-line */}
          <button id="closeBtn" tabIndex={1} type="button" aria-label="Close" className="bar-icon close-popup" onClick={closeSidebar}>
            <div className="line1" />
            <div className="line2" />
            <div className="line3" />
          </button>
          <h2 className="h1 sidebar-title"><strong>{ targetCompound.label }</strong></h2>
          <div className="sidebar-desc">
            <p>
              <strong>Compound</strong>
              {' '}
              { targetCompound.field_compound_name }
            </p>
            <p><strong>Area under investigation</strong></p>
            <p>{ _.map(targetCompound.field_areas_under_investigation, 'label').join(', ') }</p>
            <p className="pt-20">
              <strong>Scientific Pillar</strong>
              {' '}
              { targetCompound.field_associated_area && (
                <>
                  {/* eslint-disable-next-line */}
                  <Link to={`/scientific-pillars?id=${targetCompound.field_associated_area.id}`} tabIndex={1} id="splink" onBlur={() => onBlurSPLink()} style={{ color: targetCompound.field_associated_area.field_color }}>
                    { targetCompound.field_associated_area.label }
                    {' '}
                    <em className="az-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" width="11.105" height="18.947" viewBox="0 0 11.105 18.947">
                        <path
                          id="Path_2267"
                          data-name="Path 2267"
                          d="M0,0,6.009,5.811,8.972,8.676,0,17.516"
                          transform="translate(0.702 0.719)"
                          fill="none"
                          stroke={targetCompound.field_associated_area.field_color}
                          strokeWidth="2"
                        />
                      </svg>
                    </em>
                  </Link>
                </>
              ) }
            </p>
          </div>
          { tumourType && (
            <div className="action-btn">
              <Link
                to={{ pathname: `/tumour-type/${tumourType.id}/target-compound/${targetCompound.id}`, state: { prevPath: getWindowPathName(), activeDDRId } }}
                className="btn btn-light btn-block btn-radius"
                rel="nofollow,noindex"
                id="takemebtn"
                /* eslint-disable-next-line */
                tabIndex={1}
                onBlur={() => onBlurLastElement()}
              >
                <span>Take me</span>
                {' '}
                to view target mechanism details
              </Link>
            </div>
          ) }
          { targetCompound.field_compound_study_design && (
            <div className="image-block">
              <h2><strong>Compound Target Study Design</strong></h2>
              <div className="img">
                <img src={targetCompound.field_compound_study_design} alt="" />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="popup-sidebar-overlay" onClick={closeSidebar} />
    </>
  );
}

TargetCompoundSidebar.defaultProps = {
  activeDDRId: null,
};

export default TargetCompoundSidebar;
