import React from 'react';
import INarrativeColumn from '../../../../../../../interfaces/NarrativeColumn';
import Image from './Type/Image/Image';
import Text from './Type/Text/Text';
import Video from './Type/Video/Video';
import Audio from './Type/Audio/Audio';

interface IProps {
  content: INarrativeColumn,

}

function Column(props: IProps) {
  const { content } = props;

  return (
    <>
      { content.type === 'image' && (
        <Image src={content.value} svgData={content.value_svg_data} />
      )}
      { content.type === 'text' && (
        <Text content={content.value} />
      )}
      { content.type === 'numbered_list' && (
        <div className="numbered-list">
          <Text content={content.value} />
        </div>
      )}
      { content.type === 'video' && (
        <Video src={content.value} />
      )}
      { content.type === 'audio' && (
        <Audio src={content.value} />
      )}
    </>
  );
}

export default Column;
