import React, { useEffect } from 'react';
import App from '../../../store/App';
import Welcome from '../../Shared/Welcome/Welcome';
import Navigation from '../../Shared/Navigation/Navigation';
import getSiteName from '../../../helpers/Common';
import MainContentWrapper from '../../Shared/MainContenWrapper/MainContentWrapper';

function Home() {
  useEffect(() => {
    document.title = `Home | ${getSiteName()}`;
  }, []);

  const navLinks = App.GetMainNavigation();

  return (
    <MainContentWrapper wrapperClass="bg-home is-bg-ani is-bg-left" animateBodyImg addBodyHomeClass>
      <div className="inner-container">
        <div className="container">
          <Welcome
            title="<strong>AstraZeneca</strong><br /><strong>Oncology Pipeline Tool</strong>"
            subtitle="What is Science?"
            description={App.GetDescription()}
            containerCssClass="home-welcome"
          />
          <div className="clear" />
          <Navigation links={navLinks} containerCssClass="home-grid-actions" />
        </div>
      </div>
    </MainContentWrapper>
  );
}

export default Home;
