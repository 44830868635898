import React from 'react';

interface IProps {
  src: string, // Audio src.
}

function Audio(props: IProps) {
  const { src } = props;

  return (
    <div className="audio-wrapper content-grid-block">
      <audio controls controlsList="nodownload">
        <source src={src} type="audio/mp3" />
      </audio>
    </div>
  );
}

export default Audio;
