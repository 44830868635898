import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
// import Info from './Partials/Info/Info';
import CellType from '../../../store/CellType';
import NotFound from '../NotFound/NotFound';
import ImageMap from './Partials/ImageMap/ImageMap';
// import bg from '../../../assets/images/type-bg-8.webp';
import TargetCompoundSidebar from '../../Shared/TargetCompoundSidebar/TargetCompoundSidebar';
import IHotspot from '../../../interfaces/Hotspot';
import getSiteName, { getWindowPathName } from '../../../helpers/Common';
import MainContentWrapper from '../../Shared/MainContenWrapper/MainContentWrapper';

function CellOverview() {
  const { id } = useParams<{ id: string }>();
  const cell = CellType.GetById(Number(id));
  // Selected hotspot object.
  const [activeHotspot, setHotspot] = useState<IHotspot | null>(null);
  // Index of selected hotspot (use to identify which hotspot is selected,
  // as hotspot does not have ID).
  const [activeHotspotIndex, setHotspotIndex] = useState(-1);

  if (!cell) {
    return (<NotFound />);
  }

  useEffect(() => {
    document.title = `Cell Pathway | ${cell.title} | ${getSiteName()}`;
  }, []);

  // const mainStyle = {
  //  backgroundImage: `url(${bg})`,
  // };

  /**
   * Callback on closing sidebar event.
   */
  function sidebarClosed() {
    setHotspot(null);
    setHotspotIndex(-1);
    window.history.replaceState(null, document.title, getWindowPathName());
  }

  /**
   * Callback on selecting hotspot event.
   *
   * @param hotspot
   * @param index
   */
  function hotspotSelected(hotspot: IHotspot, index: number) {
    setHotspot(hotspot);
    setHotspotIndex(index);
  }

  return (
    <MainContentWrapper wrapperClass="cell-pathway-details">
      <div className="inner-container">
        <div className="container">
          <div className="type-innerpage-title">
            <div className="back-action">
              <Link to="/cell-pathway" className="btn-back btn-box ml-0">
                <em className="az-icon icon-arrow-left" />
                BACK
              </Link>
            </div>
          </div>
          <div className="clear" />
          <ImageMap
            cell={cell}
            hotspotIndex={activeHotspotIndex}
            onHotspotSelected={(hotspot, index) => hotspotSelected(hotspot, index)}
          />
        </div>
      </div>
      { activeHotspot && activeHotspot.target_compound && (
        <TargetCompoundSidebar
          targetCompound={activeHotspot.target_compound}
          onClosed={() => sidebarClosed()}
        />
      ) }
    </MainContentWrapper>
  );
}

export default CellOverview;
