/**
 * Get href attribute of the closest link element.
 *
 * @param node
 */
function getClosestLink(node: any): Element | null {
  if (node instanceof Element && node.nodeName.toLowerCase() === 'a' && node.hasAttribute('href')) {
    return node;
  }
  let current = node;
  while (current.parentElement) {
    current = current.parentElement;
    if (current instanceof Element && current.nodeName.toLowerCase() === 'a' && current.hasAttribute('href')) {
      return current;
    }
  }
  return null;
}
export default getClosestLink;
