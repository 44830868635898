import React, { useEffect, useState } from 'react';
import bgImage from '../../../assets/images/type-bg-4.webp';
import AssociatedArea from '../../../store/AssociatedArea';
import MechanismItem from './Partials/MechanismItem/MechanismItem';
import getSiteName, { getWindowLocationQuery } from '../../../helpers/Common';
import MainContentWrapper from '../../Shared/MainContenWrapper/MainContentWrapper';

function ScientificPillars() {
  useEffect(() => {
    document.title = `Scientific Pillars | ${getSiteName()}`;
  }, []);

  const mainStyle = {
    backgroundImage: `url(${bgImage})`,
  };

  const mechanisms = AssociatedArea.GetAll();
  const query = new URLSearchParams(getWindowLocationQuery());
  const mid = query.get('id');

  // Set active (expanded) mechanism accordion.
  let defaultMechanism: number[];
  if (mid) {
    defaultMechanism = [Number(mid)];
  } else {
    defaultMechanism = [];
  }
  /* else if (mechanisms.length) {
    defaultMechanism = mechanisms.map(({ id }) => Number(id));
  } */

  // Active mechanism state.
  const [activeMechanism, setActiveMechanism] = useState(defaultMechanism);

  /**
   * Handle click/change mechanism accordion.
   * @param mechanismId
   */
  function mechanismChange(mechanismId: number) {
    const mechs = [...activeMechanism];
    const index = mechs.indexOf(mechanismId);
    if (index >= 0) {
      mechs.splice(index, 1);
    } else {
      mechs.push(mechanismId);
    }
    setActiveMechanism(mechs);
  }

  return (
    <MainContentWrapper wrapperClass="bg-wrapper is-bg-ani is-bg-right page-ani-fade" wrapperStyle={mainStyle} animateBodyImg>
      <div className="inner-container">
        <div className="container">
          <div className="title-box pb-0">
            <div className="bg-content-block bg-w-auto">
              <h1 className="mb-0"><strong>Scientific Pillars</strong></h1>
            </div>
          </div>
          <div className="clear" />
        </div>

        <div className="collapsiable-card-block">
          { mechanisms.map((mechanism) => (
            <MechanismItem
              mechanism={mechanism}
              active={activeMechanism.indexOf(Number(mechanism.id)) >= 0}
              key={mechanism.id}
              onMechanismClick={(mechanismId) => mechanismChange(mechanismId)}
            />
          ))}
        </div>
      </div>
    </MainContentWrapper>
  );
}

export default ScientificPillars;
