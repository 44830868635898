import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

export default function fetch(
  password: string | null,
  isVeevaTool = false,
): Promise<AxiosResponse> {
  // Get data from API.
  const config: AxiosRequestConfig = {
    withCredentials: true,
    params: {
      api_key: process.env.REACT_APP_API_TOKEN,
      veevatool: isVeevaTool,
    },
  };
  if (password) {
    config.params.token = `${btoa(`user:${password}`)}`;
  }

  if (process.env.REACT_APP_API_AUTH_USERNAME && process.env.REACT_APP_API_AUTH_PASSWORD) {
    config.auth = {
      username: process.env.REACT_APP_API_AUTH_USERNAME,
      password: process.env.REACT_APP_API_AUTH_PASSWORD,
    };
  }

  const endpoint = window.location.pathname.substr(0, 6) === '/draft' ? process.env.REACT_APP_DRAFT_API_ENDPOINT : process.env.REACT_APP_API_ENDPOINT;

  return axios.get(<string>endpoint, config);
}
