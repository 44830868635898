import React from 'react';

interface IProps {
  content: string
}

function Text(props: IProps) {
  const { content } = props;

  return (
    <div className="content-info-block" dangerouslySetInnerHTML={{ __html: content }} />
  );
}

export default Text;
