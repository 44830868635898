import React from 'react';
import IAreaUnderInvestigationArray from '../../../../../interfaces/AreaUnderInvestigationArray';
import IAreaUnderInvestigation from '../../../../../interfaces/AreaUnderInvestigation';
import phaseCssClass from '../../../../../helpers/PhaseNumber';
import phaseMaskImg from '../../../../../assets/images/phase-mask.png';

interface IProps {
  areaUnderInvestigation: IAreaUnderInvestigationArray, // Area under investigation.
  indexValue: number,
  phaseStyle: object,
}

function AreaUnderInvestigationRow(props: IProps) {
  const { areaUnderInvestigation, indexValue, phaseStyle } = props;
  return (
    <div className={indexValue === 1 ? 'table-row bg-light' : 'table-row'}>
      {areaUnderInvestigation.map((area: IAreaUnderInvestigation) => (
        <div className="table-col" key={area.id}>
          <div className="heading">{area.label}</div>
          <div className={`phase-table ${phaseCssClass(area.field_phase.number)}`}>
            <table>
              <tr>
                <td>
                  <div className="phase-name">
                    PHASE
                    {' '}
                    {area.field_phase.number}
                  </div>
                </td>
                <td><span style={phaseStyle}><img src={phaseMaskImg} alt="" /></span></td>
                <td><span style={phaseStyle}><img src={phaseMaskImg} alt="" /></span></td>
                <td><span style={phaseStyle}><img src={phaseMaskImg} alt="" /></span></td>
                <td><span style={phaseStyle}><img src={phaseMaskImg} alt="" /></span></td>
              </tr>
            </table>
          </div>
        </div>
      ))}
    </div>
  );
}

export default AreaUnderInvestigationRow;
