import React, { useEffect } from 'react';

function PageLoader() {
  useEffect(() => {
    /* document.body.setAttribute('data-vv-pageWait', '3000');
    return () => {
      document.body.removeAttribute('data-vv-pageWait');
    }; */
  }, []);

  return (
    <div className="pageloader" data-vv-snapshot="never">
      <div className="spinner">
        <div className="ball" />
        <p>LOADING</p>
      </div>
    </div>
  );
}

export default PageLoader;
