import React from 'react';
import 'external-svg-loader';

interface IProps {
  src: string, // Image src.
  svgData?: string,
}

function Image(props: IProps) {
  const { src, svgData } = props;

  const isSVG = src && src.toLowerCase().match(/\.(svg)$/) !== null;

  return (
    <>
      { svgData ? (
        <div className="img-block content-grid-block" dangerouslySetInnerHTML={{ __html: svgData }} />
      ) : (
        <div className="img-block content-grid-block">
          { isSVG && (
            <svg data-src={src} />
          )}
          { !isSVG && (
            <img src={src} alt="" />
          )}
        </div>
      )}
    </>
  );
}

Image.defaultProps = {
  svgData: '',
};

export default Image;
