import React from 'react';

interface IProps {
  title: string,
  subtitle?: string,
  description: string,
  containerCssClass?: string
}

function Welcome(props: IProps) {
  const {
    title,
    subtitle,
    description,
    containerCssClass,
  } = props;
  return (
    <div className={`lg-pull-right ${containerCssClass}`}>
      <h1 dangerouslySetInnerHTML={{ __html: title }} />
      { subtitle
        && <h2 dangerouslySetInnerHTML={{ __html: subtitle }} /> }
      { !!description && (
        <div dangerouslySetInnerHTML={{ __html: description }} />
      )}
    </div>
  );
}

Welcome.defaultProps = {
  subtitle: '',
  containerCssClass: '',
};

export default Welcome;
