import React, { MouseEvent } from 'react';
import Panzoom, { PanzoomObject } from '@panzoom/panzoom';
import ICellType from '../../../../../interfaces/CellType';
import pinIcon from '../../../../../assets/images/icons/icon-pin.svg';
import activePinIcon from '../../../../../assets/images/icons/icon-pin-active.svg';
import IHotspot from '../../../../../interfaces/Hotspot';
import IZoom from '../../../../../interfaces/Zoom';
import { getWindowLocationQuery, getWindowPathName } from '../../../../../helpers/Common';

interface IProps {
  cell: ICellType, // CellType.
  hotspotIndex: number, // Index of active hotspot.
  // eslint-disable-next-line no-unused-vars
  onHotspotSelected: (hotspot: IHotspot, index: number) => void, // Hotspot selected callback.
}

interface IState {
  prevZoom: IZoom, // Previous zoom state.
  panzoomObj: PanzoomObject | null, // Panzoom object.
  panzoomEl: HTMLElement | null, // Panzoom element.
}

class ImageMap extends React.Component<IProps, IState> {
  /**
   * Get hotspot style (position).
   *
   * @param hotspot
   */
  static getHotspotStyle(hotspot: IHotspot) {
    const position: 'absolute' = 'absolute';
    return {
      top: `${hotspot.top}%`,
      left: `${hotspot.left}%`,
      position,
    };
  }

  constructor(props: IProps) {
    super(props);
    this.state = {
      panzoomObj: null,
      panzoomEl: null,
      prevZoom: {
        scale: 1,
        x: 0,
        y: 0,
      },
    };
    this.handleReset = this.handleReset.bind(this);
  }

  componentDidMount() {
    const panzoomEl = document.getElementById('panzoom-element');
    if (panzoomEl) {
      const panzoomObj = Panzoom(panzoomEl, {
        startScale: 1,
        minScale: 1,
        contain: 'outside',
      });
      this.setState({
        panzoomEl,
        panzoomObj,
      });

      if (panzoomEl.parentElement) {
        panzoomEl.parentElement.addEventListener('wheel', panzoomObj.zoomWithWheel);
      }

      setTimeout(() => {
        const query = new URLSearchParams(getWindowLocationQuery());
        const index = query.get('hotspot');
        if (index) {
          const element = document.getElementById(`pin-${index}`) as HTMLElement;
          if (element) {
            element.click();
          }
        }
      }, 100);
    }
  }

  componentDidUpdate(prevProps: IProps) {
    const { hotspotIndex } = this.props;
    const { panzoomEl, prevZoom } = this.state;
    if (panzoomEl && prevProps.hotspotIndex !== hotspotIndex && hotspotIndex === -1) {
      // Restore previous panzoom state.
      const newPanzoomObj = Panzoom(panzoomEl, {
        startScale: prevZoom.scale,
        minScale: 1,
        contain: 'outside',
        startX: prevZoom.x,
        startY: prevZoom.y,
      });
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        panzoomObj: newPanzoomObj,
      });

      this.refreshWheelListener(newPanzoomObj);
    }
  }

  /**
   * Handle click on hotspot.
   *
   * @param hotspot
   * @param index
   * @param event
   */
  handleHotspotClick(hotspot: IHotspot, index: number, event: MouseEvent) {
    event.preventDefault();
    const { onHotspotSelected } = this.props;
    onHotspotSelected(hotspot, index);

    const { panzoomEl, panzoomObj } = this.state;

    if (panzoomEl) {
      // Clicked spin element.
      const spin = (event.currentTarget || document.getElementById(`pin-${hotspot.tcId}`)) as HTMLElement;

      // Convert left position
      let left = 50 - Math.max(
        20, parseFloat(spin.style.left),
      ); // 50 -> 50% - which is center of the screen.
      left = (panzoomEl.clientWidth * left) / 100; // percent -> absolute value

      // Convert top position
      let top = 50 - Math.max(
        20, parseFloat(spin.style.top),
      ); // 50 -> 50% - which is center of the screen.
      top = (panzoomEl.clientHeight * top) / 100; // percent -> absolute value

      // Get current scale, X/Y positions to save in the state.
      let scale = 1;
      let x = 0;
      let y = 0;
      if (panzoomObj) {
        scale = panzoomObj.getScale();
        const pan = panzoomObj.getPan();
        x = pan.x;
        y = pan.y;
      }

      setTimeout(() => {
        const newScale = 2.5;
        /* const sidebar = document.querySelector('.popup-sidebar');
        const mapContainer = document.querySelector('.map-container');

        let leftOffset = 0;
        if (sidebar && mapContainer) {
          const docWidth = document.documentElement.clientWidth;
          const mapBoundary = mapContainer.getBoundingClientRect();
          // calculate map width. (document width - sidebar width - left map offset).
          const mapWidth = docWidth - sidebar.clientWidth - mapBoundary.x;
          leftOffset = mapWidth / 2; // Center of the map
          leftOffset /= newScale; // Map is scaled, sizes are scaled, need to calculate original.
        } */

        const newPanzoomObj = Panzoom(panzoomEl, {
          startX: left /* - leftOffset */,
          startY: top,
          minScale: 1,
          startScale: newScale,
        });

        this.setState({
          panzoomObj: newPanzoomObj,
          prevZoom: {
            scale,
            x,
            y,
          },
        });

        this.refreshWheelListener(newPanzoomObj);

        if (event.isTrusted) {
          window.history.replaceState(null, document.title, `${getWindowPathName()}?hotspot=${index + 1}`);
        }
      }, 0);
    }
  }

  /**
   * Handle map reset.
   */
  handleReset() {
    const { panzoomEl } = this.state;
    if (panzoomEl) {
      const newPanzoomObj = Panzoom(panzoomEl, {
        startScale: 1,
        minScale: 1,
        contain: 'outside',
      });
      this.setState({
        panzoomObj: newPanzoomObj,
      });

      this.refreshWheelListener(newPanzoomObj);
    }
  }

  /**
   * Refresh Wheel listener for panzoom.
   *
   * @param newPanzoomObj
   */
  refreshWheelListener(newPanzoomObj: PanzoomObject | null) {
    const { panzoomObj, panzoomEl } = this.state;

    if (panzoomEl && panzoomEl.parentElement) {
      if (panzoomObj) {
        panzoomEl.parentElement.removeEventListener('wheel', panzoomObj.zoomWithWheel);
      }
      if (newPanzoomObj) {
        panzoomEl.parentElement.addEventListener('wheel', newPanzoomObj.zoomWithWheel);
      }
    }
  }

  render() {
    const { cell, hotspotIndex } = this.props;
    const { panzoomObj } = this.state;
    return (
      <div className="image-card-block">
        <div className="image-card">
          <div className="card-body">
            <div className="map-container">
              <div className="map-action">
                <button className="action" type="button" id="zoom-in" title="Zoom In" onClick={panzoomObj?.zoomIn}>
                  <em className="az-icon icon-plus" />
                </button>
                <button className="action" type="button" id="zoom-out" title="Zoom Out" onClick={panzoomObj?.zoomOut}>
                  <em className="az-icon icon-minus" />
                </button>
                <button className="action" type="button" id="reset" title="Reset" onClick={this.handleReset.bind(this)}>
                  <em className="az-icon icon-reset" />
                </button>
              </div>
              <div className="grid--imagemap-overview-block">
                <div className="overview-block">
                  <div className="bg-content-block">
                    <h1>
                      <strong>
                        {cell.title}
                        {' '}
                        Overview
                      </strong>
                    </h1>
                    { !!cell.field_description && (
                      <p dangerouslySetInnerHTML={{ __html: cell.field_description }} />
                    )}
                  </div>
                </div>
                <div className="image-map-block">
                  <div className={`panzoom ${hotspotIndex !== -1 ? 'scale-view' : ''}`} id="panzoom-element">
                    { cell.hotspots.map((point, index) => (
                      <a
                        href={`${getWindowPathName()}?hotspot=${index + 1}`}
                        id={`pin-${index + 1}`}
                        className="map-pin toggle-popup"
                        style={ImageMap.getHotspotStyle(point)}
                        onClick={this.handleHotspotClick.bind(this, point, index)}
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                      >
                        <img src={index === hotspotIndex ? activePinIcon : pinIcon} width="30" alt="" />
                      </a>
                    ))}
                    <img src={cell.field_image} width="100%" className="img-panzoom" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ImageMap;
