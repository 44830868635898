import React, { MouseEvent } from 'react';
import Panzoom, { PanzoomObject } from '@panzoom/panzoom';
import IDDR from '../../../../../interfaces/DDR';
import IDDRHotspot from '../../../../../interfaces/DDRHotspot';
import { getWindowLocationQuery, getWindowPathName } from '../../../../../helpers/Common';

interface IProps {
  ddr: IDDR, // DDR.
  reset: boolean, // Reset scale.
  // eslint-disable-next-line no-unused-vars
  onHotspotSelected: (hotspot: IDDRHotspot) => void, // Hotspot selected callback.
}

interface IState {
  panzoomObj: PanzoomObject | null, // Panzoom object.
  panzoomEl: HTMLElement | null, // Panzoom element.
}

class ImageMap extends React.Component<IProps, IState> {
  /**
   * Get hotspot style (position, size).
   *
   * @param hotspot
   */
  static getHotspotStyle(hotspot: IDDRHotspot) {
    const position: 'absolute' = 'absolute';
    return {
      top: `${hotspot.top}%`,
      left: `${hotspot.left}%`,
      width: `${hotspot.width}%`,
      height: `${hotspot.height}%`,
      position,
    };
  }

  constructor(props: IProps) {
    super(props);
    this.state = {
      panzoomObj: null,
      panzoomEl: null,
    };
    this.handleReset = this.handleReset.bind(this);
  }

  componentDidMount() {
    const panzoomEl = document.getElementById('panzoom-element');
    if (panzoomEl) {
      const panzoomObj = Panzoom(panzoomEl, {
        startScale: 1,
        minScale: 1,
        contain: 'outside',
      });
      this.setState({
        panzoomEl,
        panzoomObj,
      });

      if (panzoomEl.parentElement) {
        panzoomEl.parentElement.addEventListener('wheel', panzoomObj.zoomWithWheel);
      }

      setTimeout(() => {
        const query = new URLSearchParams(getWindowLocationQuery());
        const index = query.get('hotspot');
        if (index) {
          const element = document.getElementById(`pin-${index}`) as HTMLElement;
          if (element) {
            element.click();
          }
        }
      }, 100);
    }
  }

  componentDidUpdate(prevProps: IProps) {
    const { reset, ddr } = this.props;
    const { panzoomEl } = this.state;
    if (panzoomEl && ((reset && !prevProps.reset) || prevProps.ddr !== ddr)) {
      // Restore previous panzoom state.
      const newPanzoomObj = Panzoom(panzoomEl, {
        startScale: 1,
        minScale: 1,
        contain: 'outside',
      });
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        panzoomObj: newPanzoomObj,
      });

      this.refreshWheelListener(newPanzoomObj);
    }
  }

  /**
   * Handle click on hotspot.
   *
   * @param hotspot
   */
  handleHotspotClick(hotspot: IDDRHotspot, index: number, event: MouseEvent) {
    event.preventDefault();

    const { onHotspotSelected, ddr } = this.props;
    onHotspotSelected(hotspot);

    if (hotspot.tcId) {
      const { panzoomEl } = this.state;

      if (panzoomEl) {
        // Image is centered.
        const left = 0;
        const top = 0;

        setTimeout(() => {
          const newScale = 1;
          const sidebar = document.querySelector('.popup-sidebar');
          const mapContainer = document.querySelector('.map-container');

          let leftOffset = 0;
          if (sidebar && mapContainer) {
            const docWidth = document.documentElement.clientWidth;
            const mapBoundary = mapContainer.getBoundingClientRect();
            // calculate map width. (document width - sidebar width - left map offset).
            const mapWidth = docWidth - sidebar.clientWidth - mapBoundary.x;
            leftOffset = mapWidth / 2; // Center of the map
            leftOffset /= newScale; // Map is scaled, sizes are scaled, need to calculate original.
          }

          const newPanzoomObj = Panzoom(panzoomEl, {
            startX: left - leftOffset,
            startY: top,
            minScale: 1,
            startScale: newScale,
          });

          this.setState({
            panzoomObj: newPanzoomObj,
          });

          this.refreshWheelListener(newPanzoomObj);

          if (event.isTrusted) {
            window.history.replaceState(null, document.title, `${getWindowPathName()}?tab=${ddr.id}&hotspot=${index + 1}`);
          }
        }, 0);
      }
    }
  }

  /**
   * Handle map reset.
   */
  handleReset() {
    const { panzoomEl } = this.state;
    if (panzoomEl) {
      const panzoomObj = Panzoom(panzoomEl, {
        startScale: 1,
        minScale: 1,
        // contain: 'outside',
      });
      this.setState({
        panzoomObj,
      });

      this.refreshWheelListener(panzoomObj);
    }
  }

  refreshWheelListener(newPanzoomObj: PanzoomObject | null) {
    const { panzoomObj, panzoomEl } = this.state;

    if (panzoomEl && panzoomEl.parentElement) {
      if (panzoomObj) {
        panzoomEl.parentElement.removeEventListener('wheel', panzoomObj.zoomWithWheel);
      }
      if (newPanzoomObj) {
        panzoomEl.parentElement.addEventListener('wheel', newPanzoomObj.zoomWithWheel);
      }
    }
  }

  render() {
    const { ddr } = this.props;
    const { panzoomObj } = this.state;
    return (
      <div className="image-card-block">
        <div className="image-card">
          <div className="card-body">
            <div className="map-container">
              <div className="map-action">
                <button className="action zoom-in" type="button" id="zoom-in" title="Zoom In" onClick={panzoomObj?.zoomIn}>
                  <em className="az-icon icon-plus" />
                </button>
                <button className="action zoom-out" type="button" id="zoom-out" title="Zoom Out" onClick={panzoomObj?.zoomOut}>
                  <em className="az-icon icon-minus" />
                </button>
                <button className="action reset" type="button" id="reset" title="Reset" onClick={this.handleReset.bind(this)}>
                  <em className="az-icon icon-reset" />
                </button>
              </div>
              <div className="image-map-block">
                <div className={`panzoom panzoom-element ${ddr ? 'scale-view' : ''}`} id="panzoom-element">
                  { ddr.hotspots.map((point, index) => (
                    <a
                      href={`${getWindowPathName()}?tab=${ddr.id}&hotspot=${index + 1}`}
                      id={`pin-${index + 1}`}
                      className="map-pin toggle-popup"
                      style={ImageMap.getHotspotStyle(point)}
                      onClick={this.handleHotspotClick.bind(this, point, index)}
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                    >
                      <span />
                    </a>
                  ))}
                  <img src={ddr.field_image} width="100%" className="img-panzoom" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ImageMap;
