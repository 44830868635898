import _ from 'lodash';
import { useContext } from 'react';
import IAbbreviation from '../interfaces/Abbreviation';
import TumourType from './TumourType';
import IApp from '../interfaces/App';
import AppContext from '../context/AppContext';

export default {
  // Get all abbreviations.
  GetAll: (): IAbbreviation[] => {
    let abbreviations: IAbbreviation[] = [];
    const abbrIds: number[] = [];
    const tumorTypes = TumourType.GetAll();
    tumorTypes.forEach((tumorType) => {
      const targetCompounds = TumourType.GetTargetCompounds(tumorType.id);
      targetCompounds.forEach((targetCompound) => {
        targetCompound.field_abbreviations.forEach((abbr) => {
          if (abbrIds.indexOf(abbr.id) < 0) {
            abbrIds.push(abbr.id);
            abbreviations.push(abbr);
          }
        });
      });
    });

    abbreviations = _.orderBy(abbreviations, [(abbreviation) => abbreviation.label.toUpperCase()], ['asc']);

    return abbreviations;
  },

  // Get popular glossary terms
  GetPopularGlossaryTerms: (): string[] => {
    const data: IApp = useContext(AppContext);

    return data && data.popular_glossary_terms ? data.popular_glossary_terms : [];
  },
};
