import React, { MouseEvent } from 'react';
import { getWindowPathName } from '../../../../../helpers/Common';

interface IProps {
  activeTab: string | null, // Active tab.
  // eslint-disable-next-line no-unused-vars
  onTabClick: (tab: string) => void // Tab onclick callback.
}

interface IState {}

class Tabs extends React.Component<IProps, IState> {
  /**
   * Handle click on tab event.
   *
   * @param tab
   * @param event
   */
  handleClick(tab: string, event: MouseEvent) {
    event.preventDefault();
    const { activeTab, onTabClick } = this.props;
    if (activeTab !== tab) {
      onTabClick(tab);
      window.history.replaceState(null, document.title, `${getWindowPathName()}?tab=${tab}`);
    }
  }

  render() {
    const { activeTab } = this.props;
    const path = getWindowPathName();
    return (
      <div className="container">
        <div className="custom-tabs">
          <div className="tabs">
            <a
              href={`${path}?tab=overview`}
              className={!activeTab || activeTab === 'overview' ? 'active' : ''}
              onClick={this.handleClick.bind(this, 'overview')}
            >
              <strong>Overview</strong>
            </a>
            <a
              href={`${path}?tab=clinical-trials`}
              className={activeTab === 'clinical-trials' ? 'active' : ''}
              onClick={this.handleClick.bind(this, 'clinical-trials')}
            >
              <strong>Clinical Trials</strong>
            </a>
            <a
              href={`${path}?tab=abbreviations`}
              className={activeTab === 'abbreviations' ? 'active' : ''}
              onClick={this.handleClick.bind(this, 'abbreviations')}
            >
              <strong>Abbreviations</strong>
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default Tabs;
