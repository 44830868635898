import React from 'react';
import {
  Route, Switch, withRouter, RouteComponentProps,
} from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import routes from '../../../routes';
import './Content.css';
import NotFound from '../../Pages/NotFound/NotFound';

interface IState {
  prevDepth: number, // URL path depth to detect transition direction.
}

class Content extends React.Component<RouteComponentProps, IState> {
  /**
   * Handle transition start event.
   */
  static transitionStarted() {
    document.body.classList.add('transition');
  }

  /**
   * Handle transition end event.
   */
  static transitionEnded() {
    document.body.classList.remove('transition');
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      prevDepth: this.getPathDepth(),
    };
    this.getPathDepth = this.getPathDepth.bind(this);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps() {
    this.setState({
      prevDepth: this.getPathDepth(),
    });
  }

  /**
   * Get URL path depth to detect transition direction.
   */
  getPathDepth() {
    const { location } = this.props;
    let pathArr = location.pathname.split('/');
    pathArr = pathArr.filter((n) => n !== '');
    return pathArr.length;
  }

  render() {
    const { location } = this.props;
    const { prevDepth } = this.state;
    const currentKey = location.pathname || '/';
    const timeout = { enter: 0, exit: 0 };

    return (
      <div className="transition-wrapper">
        <Switch location={location}>
          { routes.map((route) => (
            <Route path={route.path} component={route.component} exact key={route.path} />
          )) }
          <Route path="*" exact component={NotFound} />
        </Switch>
        <div className="bubble-wrapper">
          { Array.from(Array(40).keys()).map((i) => (
            <div className={`bubble bubble-${i + 1}`} key={i} />
          ))}
        </div>
      </div>
    );

    // eslint-disable-next-line no-unreachable
    return (
      <TransitionGroup
        className={`transition-wrapper ${this.getPathDepth() - prevDepth >= 0 ? 'left' : 'right'}`}
      >
        <CSSTransition
          key={currentKey}
          timeout={timeout}
          classNames="content-wrapper"
          mountOnEnter={false}
          unmountOnExit
          onEnter={Content.transitionStarted}
          onExited={Content.transitionEnded}
        >
          <div>
            <Switch location={location}>
              { routes.map((route) => (
                <Route path={route.path} component={route.component} exact key={route.path} />
              )) }
              <Route path="*" exact component={NotFound} />
            </Switch>
            <div className="bubble-wrapper">
              { Array.from(Array(40).keys()).map((i) => (
                <div className={`bubble bubble-${i + 1}`} key={i} />
              ))}
            </div>
          </div>
        </CSSTransition>
      </TransitionGroup>
    );
  }
}

export default withRouter(Content);
