import React from 'react';
import Panzoom, { PanzoomObject } from '@panzoom/panzoom';
import IZoom from '../../../../../../interfaces/Zoom';

interface IProps {
  srcImg: string, // CellType.
}

interface IState {
  prevZoom: IZoom, // Previous zoom state.
  panzoomObj: PanzoomObject | null, // Panzoom object.
  panzoomEl: HTMLElement | null, // Panzoom element.
}

class StudyImageMap extends React.Component<IProps, IState> {
  /**
   * Get hotspot style (position).
   *
   * @param hotspot
   */

  constructor(props: IProps) {
    super(props);
    this.state = {
      panzoomObj: null,
      panzoomEl: null,
      prevZoom: {
        scale: 1,
        x: 0,
        y: 0,
      },
    };
    this.handleReset = this.handleReset.bind(this);
  }

  componentDidMount() {
    const panzoomEl = document.getElementById('panzoom-element');
    if (panzoomEl) {
      const panzoomObj = Panzoom(panzoomEl, {
        startScale: 1,
        minScale: 1,
        contain: 'outside',
      });
      this.setState({
        panzoomEl,
        panzoomObj,
      });

      if (panzoomEl.parentElement) {
        panzoomEl.parentElement.addEventListener('wheel', panzoomObj.zoomWithWheel);
      }
    }
  }

  componentDidUpdate() {
    const { prevZoom } = this.state;
    console.log(prevZoom);
    /* if (panzoomEl) {
      // Restore previous panzoom state.
      const newPanzoomObj = Panzoom(panzoomEl, {
        startScale: prevZoom.scale,
        minScale: 1,
        contain: 'outside',
        startX: prevZoom.x,
        startY: prevZoom.y,
      });
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        panzoomObj: newPanzoomObj,
      });

      this.refreshWheelListener(newPanzoomObj);
    } */
  }

  /**
   * Handle map reset.
   */
  handleReset() {
    const { panzoomEl, panzoomObj } = this.state;
    if (panzoomEl && panzoomObj) {
      panzoomObj.reset();
      /* const newPanzoomObj = Panzoom(panzoomEl, {
        startScale: 1,
        minScale: 1,
        contain: 'outside',
      });
      this.setState({
        panzoomObj: newPanzoomObj,
      });

      this.refreshWheelListener(newPanzoomObj); */
    }
  }

  /**
   * Refresh Wheel listener for panzoom.
   *
   * @param newPanzoomObj
   */
  refreshWheelListener(newPanzoomObj: PanzoomObject | null) {
    const { panzoomObj, panzoomEl } = this.state;

    if (panzoomEl && panzoomEl.parentElement) {
      if (panzoomObj) {
        panzoomEl.parentElement.removeEventListener('wheel', panzoomObj.zoomWithWheel);
      }
      if (newPanzoomObj) {
        panzoomEl.parentElement.addEventListener('wheel', newPanzoomObj.zoomWithWheel);
      }
    }
  }

  render() {
    const { srcImg } = this.props;
    const { panzoomObj } = this.state;
    return (
      <div className="image-card">
        <div className="card-body">
          <div className="map-container">
            <div className="map-action">
              <button className="action" type="button" id="zoom-in" title="Zoom In" onClick={panzoomObj?.zoomIn}>
                <em className="az-icon icon-plus" />
              </button>
              <button className="action" type="button" id="zoom-out" title="Zoom Out" onClick={panzoomObj?.zoomOut}>
                <em className="az-icon icon-minus" />
              </button>
              <button className="action" type="button" id="reset" title="Reset" onClick={this.handleReset.bind(this)}>
                <em className="az-icon icon-reset" />
              </button>
            </div>
            <div className="grid--imagemap-overview-block">
              <div className="image-map-block">
                <div className="panzoom scale-view" id="panzoom-element">
                  <img src={srcImg} width="100%" className="img-panzoom" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default StudyImageMap;
