import React from 'react';
import { Link } from 'react-router-dom';
import ITargetCompound from '../../../../../interfaces/TargetCompound';

interface IProps {
  targetCompound: ITargetCompound, // Target/compound.
  active: boolean, // Is tab active
  onSelected: () => void,
}

function Abbreviations(props: IProps) {
  const { targetCompound, active, onSelected } = props;

  /**
   * Handle header click.
   */
  function handleHeaderClick() {
    onSelected();
  }

  return (
    <div className={`tab-content ${active ? 'active' : ''}`}>
      <div className={`tab-header collapse-action ${active ? 'active' : ''}`} onClick={handleHeaderClick}>
        <h2><strong>Abbreviations</strong></h2>
        <em className="az-icon icon-arrow-right-white" />
      </div>
      <div className={`tab-content-body ${active ? 'active' : ''}`}>
        <div className="container">
          <div className="tab-content-title grid-col-2 align-items-center">
            <div>
              <h2 className="mb-0"><strong>Abbreviations</strong></h2>
            </div>
            <div className="action-btn">
              <Link to="/glossary" className="btn btn-light btn-icon btn-sm btn-min-block">
                View full glossary
                {' '}
                <em className="az-icon icon-arrow-right-white" />
              </Link>
            </div>
          </div>
          <div className="abbreviations-block">
            <ul className="abbreviations-data-lists">
              { targetCompound.field_abbreviations.map((abbr) => (
                <li key={abbr.id}>
                  <h3><strong>{ abbr.label }</strong></h3>
                  <p>{ abbr.field_definition }</p>
                </li>
              )) }
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Abbreviations;
