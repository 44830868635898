import React, { useEffect, useState } from 'react';
import INarrativeContentItem from '../../../../../interfaces/NarrativeContentItem';
import Card from './Card/Card';

interface IProps {
  items: INarrativeContentItem[]
}

function ContentAccordion(props: IProps) {
  const { items } = props;

  const [activeIndexes, setActiveIndexes] = useState<number[]>([0]);

  const handleClick = (index: number) => {
    const aIndex: number = activeIndexes.indexOf(index);
    const aIndexes = activeIndexes.slice();
    if (aIndex > -1) {
      aIndexes.splice(aIndex, 1);
      setActiveIndexes(aIndexes);
    } else {
      aIndexes.push(index);
      setActiveIndexes(aIndexes);
      setTimeout(() => {
        const card = document.querySelector<HTMLElement>(`.narrative-collapsiable-card-block .collapse-card:nth-child(${index + 1})`);
        if (card) {
          window.scrollTo({
            top: card.offsetTop,
            behavior: 'smooth',
          });
        }
      });
    }
  };

  useEffect(() => {
    if (items && items.length > 1) {
      items.forEach((item, index) => {
        const cardHeader = document.querySelector(`#cardHeader${index}`);
        if (cardHeader) {
          if (activeIndexes.indexOf(index) === -1) {
            cardHeader.setAttribute('data-vv-action', 'click');
            cardHeader.setAttribute('data-vv-siteEntry', 'true');

            if (index > 0) {
              cardHeader.setAttribute('data-vv-siteEntrySeq', index.toString());
            }
            cardHeader.setAttribute('data-vv-snapshot', 'never');
          }
        }
      });
    }
  }, [items && items.length]);

  return (
    <div className="collapsiable-card-block narrative-collapsiable-card-block">
      { items.map((item, index) => (
        <Card
          title={item.title}
          rows={item.type === 'content' ? item.rows : null}
          auis={item.type !== 'content' ? item.auis : null}
          active={activeIndexes.indexOf(index) > -1}
          index={index}
          onClick={(clickedIndex) => handleClick(clickedIndex)}
        />
      ))}
    </div>
  );
}

export default ContentAccordion;
