import React from 'react';
import { Link } from 'react-router-dom';

function Info() {
  return (
    <div className="type-innerpage-title">
      <div className="back-action">
        <Link to="/" className="btn-back">
          <em className="az-icon icon-arrow-left" />
          BACK
        </Link>
      </div>
      <div className="bg-content-block">
        <h1><strong>Browse by Tumor Type</strong></h1>
        <p>
          Our industry-leading oncology pipeline is broad and exciting,
          addressing multiple disease pathways and allowing for a variety of
          combination therapies.
        </p>
      </div>
    </div>
  );
}

export default Info;
