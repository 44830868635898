import React from 'react';
import { Link } from 'react-router-dom';
import TumourType from '../../../../../store/TumourType';

function TumourTypeList() {
  return (
    <ul className="type-sub-links">
      { TumourType.GetAll().map((item) => (
        <li key={item.id}>
          <Link to={`/tumour-type/${item.id}`} title={item.label}>
            <h3><strong>{ item.label }</strong></h3>
            <span><img src={item.field_icon} alt={item.label} /></span>
          </Link>
        </li>
      )) }
    </ul>
  );
}

export default TumourTypeList;
