import React from 'react';
import Brand from '../Brand/Brand';
import MainNavigation from '../MainNavigation/MainNavigation';

function Header() {
  return (
    <header className="main-header is-sticky-header">
      <div className="container">
        <nav className="navbar">
          <Brand />
          <MainNavigation />
        </nav>
      </div>
    </header>
  );
}

export default Header;
