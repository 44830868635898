import React from 'react';
import { Link } from 'react-router-dom';
import IMenuItem from '../../../interfaces/MenuItem';

interface IProps {
  links: IMenuItem[],
  containerCssClass?: string
}

function Navigation(props: IProps) {
  const { links, containerCssClass } = props;
  return (
    <div className={containerCssClass}>
      <ul className={`type-links ${links.length < 4 ? 'is-links-end' : ''}`}>
        { links.map((link) => (
          <li key={link.url}>
            <Link to={link.url}>
              <span>
                <strong>{link.label}</strong>
                <em className="az-icon icon-nav-arrow" />
              </span>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

Navigation.defaultProps = {
  containerCssClass: '',
};

export default Navigation;
