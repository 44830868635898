import React from 'react';
import INarrativeColumn from '../../../../../../interfaces/NarrativeColumn';
import Column from './Column/Column';
import INarrativeAUISItem from '../../../../../../interfaces/NarrativeAUISItem';
import ClinicalTrials from './Column/Type/ClinicalTrials/ClinicalTrials';

interface IProps {
  title: string,
  rows: [INarrativeColumn[]] | null,
  auis: INarrativeAUISItem[] | null,
  active: boolean,
  index: number,
  // eslint-disable-next-line no-unused-vars
  onClick: (index: number) => void // Accordion change/click callback.
}

function Card(props: IProps) {
  const {
    title,
    rows,
    auis,
    active,
    index,
    onClick,
  } = props;

  return (
    <div className="collapse-card">
      <div className="container">
        <button
          type="button"
          id={`cardHeader${index}`}
          className={`card-header header-action ${active ? 'active' : ''}`}
          onClick={() => onClick(index)}
        >
          <h2><strong dangerouslySetInnerHTML={{ __html: title }} /></h2>
          {' '}
          <em className="collapse-icon" />
        </button>
      </div>
      <div className={`card-body ${active ? 'active' : ''}`}>
        <div className="container">
          <div className="inner-content">
            <div className="content-block">
              { rows && rows.map((row) => (
                <>
                  { row.length === 1 && (
                    <Column content={row[0]} />
                  )}
                  { row.length > 1 && (
                    <div className="row content-grid-block">
                      { row.map((column) => (
                        <div className={`col-12 col-md-12 col-lg-${row.length === 3 ? '4' : '6'} col-xl-${row.length === 3 ? '4' : '6'}`}>
                          <Column content={column} />
                        </div>
                      ))}
                    </div>
                  )}
                </>
              ))}
              { auis && (
                <ClinicalTrials auis={auis} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Card;
