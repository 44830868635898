export default class Internal {
  /**
   * Get storage key for password.
   */
  static getPassToken(): string {
    return btoa(window.location.hostname + process.env.REACT_APP_API_TOKEN);
  }

  /**
   * Set password into local storage.
   *
   * @param password
   */
  static setPassword(password: string) {
    localStorage.setItem(Internal.getPassToken(), btoa(`${process.env.REACT_APP_API_TOKEN}_${password}`));
  }

  /**
   * Get password from local storage.
   */
  static getPassword(): string | null {
    try {
      const pass = localStorage.getItem(Internal.getPassToken());

      if (!pass) {
        return null;
      }

      const passParts = atob(pass).split('_');

      return passParts.length > 1 ? passParts[1] : null;
    } catch (e) {
      console.error(e);

      return null;
    }
  }
}
