import { useContext } from 'react';
import IApp from '../interfaces/App';
import AppContext from '../context/AppContext';
import ICellType from '../interfaces/CellType';
import TargetCompound from './TargetCompound';
import IHotspot from '../interfaces/Hotspot';

export default {
  // Get all Cell Types.
  GetAll: (): ICellType[] => {
    const data: IApp = useContext(AppContext);

    if (!data || !data.cell_types) {
      return [];
    }

    return data.cell_types.map((cell) => {
      const hotspots: IHotspot[] = [];
      cell.hotspots.forEach((hotspot) => {
        const tcid = Number(hotspot.tcId);
        const tumourType = TargetCompound.GetTumourTypeByTargetCompoundId(Number(tcid));
        if (tumourType) {
          // Assign target/compound object to hotspot
          const targetCompound = TargetCompound.GetById(Number(tumourType.id), tcid);
          if (targetCompound) {
            const point = { ...hotspot, target_compound: targetCompound };
            hotspots.push(point);
          }
        }
      });
      return { ...cell, hotspots };
    });
  },

  // Get Cell Type by ID.
  GetById: (id: number): ICellType | null => {
    const data: IApp = useContext(AppContext);

    if (!data || !data.cell_types) {
      return null;
    }

    const cellType = data.cell_types.find((cell) => Number(cell.id) === id);

    if (!cellType) {
      return null;
    }

    const hotspots: IHotspot[] = [];
    cellType.hotspots.forEach((hotspot) => {
      const tcid = Number(hotspot.tcId);
      const tumourType = TargetCompound.GetTumourTypeByTargetCompoundId(Number(tcid));
      if (tumourType) {
        // Assign target/compound object to hotspot
        const targetCompound = TargetCompound.GetById(Number(tumourType.id), tcid);
        if (targetCompound) {
          const point = { ...hotspot, target_compound: targetCompound };
          hotspots.push(point);
        }
      }
    });

    return { ...cellType, hotspots };
  },
};
