import React from 'react';
import IMechanism from '../../../../../interfaces/Mechanism';
import TargetCompoundItem from '../TargetCompoundItem/TargetCompoundItem';

interface IProps {
  mechanism: IMechanism // Mechanism (Associated area).
}

function MechanismTable(props: IProps) {
  const { mechanism } = props;
  const phaseStyle = {
    background: `${mechanism.field_color}70`,
  };
  return (
    <div className="collapse-table-content-block">
      <div className="table-header">
        <div className="title">Compound</div>
        <div className="title m-hide">Target</div>
      </div>
      { mechanism.field_targets_compounds.map((targetCompound, index) => (
        <TargetCompoundItem
          targetCompound={targetCompound}
          key={`${mechanism.id}-${targetCompound.id}`}
          indexValue={index % 2 === 0 ? 1 : 0}
          phaseStyle={phaseStyle}
        />
      ))}
    </div>
  );
}

export default MechanismTable;
