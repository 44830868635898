import { useContext } from 'react';
import AppContext from '../context/AppContext';
import IApp from '../interfaces/App';
import IMenuItem from '../interfaces/MenuItem';

export default {
  // Get disclaimer.
  GetDisclaimer: (): string => {
    const data: IApp = useContext(AppContext);

    return data && data.field_disclaimer ? data.field_disclaimer.body : '';
  },

  // Get Veeva ID.
  GetVeevaId: (): string => {
    const data: IApp = useContext(AppContext);

    return data && data.field_veeva_id ? data.field_veeva_id : '';
  },

  // Get preparation date.
  GetPreparationDate: (): string => {
    const data: IApp = useContext(AppContext);

    return data && data.field_date_of_preparation ? data.field_date_of_preparation : '';
  },

  // Get description.
  GetDescription: (): string => {
    const data: IApp = useContext(AppContext);

    return data && data.body ? data.body : '';
  },

  // Get contact us link.
  GetContactLink: (): string => {
    const data: IApp = useContext(AppContext);

    return data && data.field_contact_us_link ? data.field_contact_us_link : '';
  },

  // Get cookie policy HTML URL.
  GetCookiePolicyHtmlUrl: (): string => {
    const data: IApp = useContext(AppContext);

    return data && data.field_cookie_policy_html_url ? data.field_cookie_policy_html_url : '';
  },

  // Get country iso code.
  GetCountryISOCode: (): string => {
    const data: IApp = useContext(AppContext);

    return data && data.field_country_iso_code ? data.field_country_iso_code : '';
  },

  // Get main navigation inks.
  GetMainNavigation: (): IMenuItem[] => {
    const data: IApp = useContext(AppContext);

    return data && data.field_menu_items ? data.field_menu_items : [];
  },

  // Get footer navigation inks.
  GetFooterNavigation: (): IMenuItem[] => {
    const data: IApp = useContext(AppContext);

    return data && data.field_footer_menu_items ? data.field_footer_menu_items : [];
  },
};
