import React from 'react';
import App from '../../../store/App';

function FooterDisclaimer() {
  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: App.GetDisclaimer() }} />
      <p className="info">
        Veeva ID:
        {' '}
        { App.GetVeevaId() }
        {' '}
        {`Last ${App.GetCountryISOCode().toLowerCase() === 'us' ? 'U' : 'u'}pdated`}
        {' '}
        { App.GetPreparationDate() }
        <br />
        ©
        { new Date().getFullYear() }
        {' '}
        AstraZeneca. All rights
        reserved.
      </p>
    </>
  );
}

export default FooterDisclaimer;
