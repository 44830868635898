import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import icon404 from '../../../assets/images/error/icon-error-404.svg';
import getSiteName from '../../../helpers/Common';
import MainContentWrapper from '../../Shared/MainContenWrapper/MainContentWrapper';

function NotFound() {
  useEffect(() => {
    document.title = `Page not found | ${getSiteName()}`;
  }, []);

  return (
    <MainContentWrapper wrapperClass="server-wrapper">
      <div className="server-error-block">
        <div className="container">
          <div className="server-error-card">
            <div className="content">
              <p className="error-label"><strong>404</strong></p>
              <div className="img">
                <img src={icon404} alt="Page not found" />
              </div>
              <h1><strong>Page not found</strong></h1>
              <div>
                <Link to="/" className="btn btn-theme">Back to Home</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainContentWrapper>
  );
}

export default NotFound;
