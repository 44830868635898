import React from 'react';
import App from '../../../store/App';

function FooterNavigation() {
  const contactLink = App.GetContactLink();
  const cookiePolicyLink = App.GetCookiePolicyHtmlUrl();
  const menuLinks = App.GetFooterNavigation();

  /**
   * Handle Continue button click event.
   */
  /* const handleContinueClick = (e: any, refUrl: string) => {
    e.preventDefault();
    window.open(refUrl, '_system', 'location=yes');
  }; */

  return (
    <ul className="menu">
      { menuLinks.map((link, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <li key={index}>
          <a href={link.url} className="exclude-exit-ramp-with-popup">{ link.label }</a>
        </li>
      ))}
      { cookiePolicyLink && (
        <li>
          <a href={cookiePolicyLink} className="CookieReportsLink exclude-exit-ramp-with-popup">Cookie Notice</a>
        </li>
      )}
      { contactLink && (
        <li>
          <a className="exclude-exit-ramp-with-popup" href={contactLink}>Contact Us</a>
        </li>
      )}
    </ul>
  );
}

export default FooterNavigation;
