import React from 'react';
import getClosestLink from '../../../helpers/Url';
import IFrameSidebar from '../IFrameSidebar/IFrameSidebar';
import { getWindowLocationQuery } from '../../../helpers/Common';

interface IProps {}

interface IState {
  url: string, // Link URL.
  showPopupUrl: string,
  showOfflineMsg: boolean,
  useIframe: boolean,
}

class ExitRamp extends React.Component<IProps, IState> {
  activeEle:any = null;

  tabData:any[] = [];

  constructor(props: IProps) {
    super(props);
    this.state = {
      url: '',
      showPopupUrl: '',
      showOfflineMsg: false,
      useIframe: true,
    };
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.handleContinueClick = this.handleContinueClick.bind(this);
    this.handleOKClick = this.handleOKClick.bind(this);
    this.handleLinkClick = this.handleLinkClick.bind(this);
  }

  componentDidMount() {
    if (getWindowLocationQuery().indexOf('showRamp=1') !== -1) {
      this.setState({
        url: 'ramp',
      });
      setTimeout(() => this.preventTabOutside(), 500);
    }

    window.addEventListener('click', this.handleLinkClick);
  }

  /**
   * Handle Cancel button click event.
   */
  handleCancelClick() {
    this.setState({
      url: '',
      useIframe: true,
    });
    setTimeout(() => this.enableTabOutside(), 500);
  }

  /**
   * Handle Continue button click event.
   */
  handleContinueClick(e: any, url: string) {
    e.preventDefault();
    const { useIframe } = this.state;
    if (window.innerWidth > 1024 || !useIframe) {
      window.open(url, '_blank');
    } else {
      this.setState({ showPopupUrl: url });
    }
  }

  handleOKClick() {
    this.setState({
      showOfflineMsg: false,
    });
  }

  /**
   * Handle click on any link event.
   *
   * @param event
   */
  handleLinkClick(event: Event) {
    const { target } = event;
    const link = getClosestLink(target);
    if (!navigator.onLine) {
      const href = link?.getAttribute('href') || '';
      if (href.includes('http')) {
        this.setState({
          showOfflineMsg: true,
        });
        event.preventDefault();
        return;
      }
    }

    if (link?.classList.contains('exclude-exit-ramp')) {
      return;
    }

    if (link?.classList.contains('exclude-exit-ramp-with-popup')) {
      const href = link?.getAttribute('href') || '';
      event.preventDefault();
      let useFrame = true;
      if (link?.classList.contains('exclude-iframe')) {
        useFrame = false;
      }
      this.setState({ useIframe: useFrame });
      this.handleContinueClick(event, href);
      return;
    }

    const href = link?.getAttribute('href');
    if (href) {
      const tempLink = document.createElement('a');
      tempLink.href = href;
      if (tempLink.hostname !== window.location.hostname) {
        let useFrame = true;
        if (link?.classList.contains('exclude-iframe')) {
          useFrame = false;
        }
        this.setState({
          url: href,
          useIframe: useFrame,
        });
        setTimeout(() => this.preventTabOutside(), 500);
        event.preventDefault();
      }
    }
  }

  preventTabOutside = () => {
    const selector = 'a[href], area[href], input:not([disabled]):not([type="hidden"]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], *[contenteditable]';
    this.activeEle = document.activeElement;
    const modal = document.getElementById('exitrampblock');
    if (modal) {
      const tabbableElements = document.querySelectorAll(selector);
      this.tabData = Array.from(tabbableElements)
      // filter out any elements within the modal
        .filter((elem) => !modal?.contains(elem))
        // store refs to the element and its original tabindex
        .map((elem) => {
          // capture original tab index, if it exists
          const tabIndex = elem.hasAttribute('tabindex')
            ? elem.getAttribute('tabindex')
            : null;
          // temporarily set the tabindex to -1
          elem.setAttribute('tabindex', '-1');
          return { elem, tabIndex };
        });
    }
  };

  enableTabOutside = () => {
    this.tabData.forEach(({ elem, tabIndex }) => {
      if (tabIndex === null) {
        elem.removeAttribute('tabindex');
      } else {
        elem.setAttribute('tabindex', tabIndex);
      }
    });
    this.tabData = [];
    if (this.activeEle) {
      this.activeEle.focus();
      this.activeEle = null;
    }
  };

  render() {
    const { url, showPopupUrl, showOfflineMsg } = this.state;
    if (showOfflineMsg) {
      return (
        <>
          <div id="exitrampblock" className={`custom-modal ${showOfflineMsg ? 'is-show' : ''}`}>
            <div className="wrapper">
              <div className="content" style={{ maxWidth: '600px', margin: '0 auto' }}>
                <div className="content-body">
                  <div className="row">
                    <div className="col-12">
                      <h2 className="text-center with-border"><strong>No Internet Connection</strong></h2>
                    </div>
                  </div>
                </div>
                <button type="button" className="btn btn-theme btn-full text-center exclude-exit-ramp" disabled={!showOfflineMsg} onClick={this.handleOKClick}>Ok</button>
              </div>
            </div>
          </div>
        </>
      );
    }
    return (
      <>
        <div id="exitrampblock" className={`custom-modal ${url ? 'is-show' : ''}`}>
          <div className="wrapper">
            <div className="content">
              <div className="content-body">
                <div className="row">
                  <div className="col-12">
                    <h2 className="with-border"><strong>You are now leaving</strong></h2>
                    <div>
                      <p>
                        You have selected a link
                        that will take you to a site maintained by a third party.
                      </p>
                      <p>
                        <strong>Click ‘Cancel’ to return back or ‘Continue’ to proceed.</strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="content-footer">
                <button type="button" className="btn btn-outline-theme btn-full popup-close" disabled={!url} onClick={this.handleCancelClick}>Cancel</button>
                <button type="button" className="btn btn-theme btn-full text-center exclude-exit-ramp" disabled={!url} onClick={(e) => this.handleContinueClick(e, url)}>Continue</button>
              </div>
            </div>
          </div>
        </div>
        {showPopupUrl && (
          <IFrameSidebar url={showPopupUrl} onClosed={() => this.setState({ showPopupUrl: '' })} />
        )}
      </>
    );
  }
}

export default ExitRamp;
