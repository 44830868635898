import React, { BaseSyntheticEvent } from 'react';
import { HashLink } from 'react-router-hash-link';

interface IProps {
  chars: string[] // Header characters.
}

function ScrollingHeader(props: IProps) {
  /**
   * Handle window scroll event to make header fixed.
   */
  function handleScroll() {
    if (document.querySelector('.sticky-sorting')) {
      const el = document.querySelector('.sticky-sorting');
      const cel = document.querySelector('.filter-content-block');
      if (el && cel) {
        const t = cel.getBoundingClientRect().top;
        if (t <= 0) {
          el.classList.add('sticky-fixed');
        } else {
          el.classList.remove('sticky-fixed');
        }
      }
    }
  }

  /**
   * Handle click event on header character.
   *
   * @param event
   */
  function handleCharClick(event: BaseSyntheticEvent) {
    document.querySelectorAll('.sort-link').forEach((elm) => {
      elm.classList.remove('active');
    });
    event.target.classList.add('active');
  }

  window.addEventListener('scroll', handleScroll);

  const { chars } = props;

  return (
    <div className="sticky-sorting">
      <div className="sorting-list">
        { chars.map((char) => (
          <div onClick={handleCharClick}>
            <HashLink smooth to={`#${char}`} className="sort-link" key={char}>{ char }</HashLink>
          </div>
        )) }
      </div>
    </div>
  );
}

export default ScrollingHeader;
