import React from 'react';
import QRCode from 'react-qr-code';
import IClinicalTrial from '../../../../../../interfaces/ClinicalTrial';

interface IProps {
  url: string, // external URL to redirect.
  takeMeLabel: string, // Is URL pubmed or not.
  clinicalTrial: IClinicalTrial, // Clinical Trial.
  excludeIFrame: boolean,
  isLocator: boolean,
  onClose: () => void // Popup close callback.
}

interface IState {}

class LeavingPopup extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    // this.handleContinue = this.handleContinue.bind(this);
  }

  /**
   * Handle close button click.
   */
  handleClose() {
    const { onClose } = this.props;
    onClose();
  }

  /**
   * Handle Continue button click.
   */
  /* handleContinue(e: any) {
    e.preventDefault();
    const { url } = this.props;
    window.open(url, '_system', 'location=yes');
  } */

  render() {
    const {
      url, clinicalTrial, takeMeLabel, excludeIFrame, isLocator,
    } = this.props;

    return (
      <div className="custom-modal is-show">
        <div className="wrapper">
          <div className="content">
            <div className="content-body">
              <div className="row">
                <div className="col-12 col-md-7 col-lg-8 col-xl-9">
                  <h2 className="with-border">
                    <strong>{ clinicalTrial.label }</strong>
                    {' '}
                    trial overview
                  </h2>
                  {isLocator ? (
                    <div>
                      <h3>
                        Scan the QR code to access further trial information.
                      </h3>
                      <p>
                        <strong>Click ‘Close’ to close this window.</strong>
                      </p>
                    </div>
                  ) : (
                    <div>
                      <h3>
                        Take me to
                        {' '}
                        <strong>{takeMeLabel}</strong>
                      </h3>
                      <p>
                        You are now leaving the AstraZeneca Oncology Portfolio.
                        <br />
                        You have selected a link that will take you to a
                        site maintained by a third party.
                      </p>
                      <p>
                        <strong>Click ‘Cancel’ to return back or ‘Continue’ to proceed.</strong>
                      </p>
                    </div>
                  )}
                </div>
                <div className="col-12 col-md-5 col-lg-4 col-xl-3 align-self-end">
                  <div className="qr-code-img">
                    <QRCode value={url} />
                    <span className="qr-code-text">Scan me</span>
                  </div>
                </div>
              </div>
            </div>
            {isLocator ? (
              <div className="content-footer" style={{ justifyContent: 'center' }}>
                <span style={{ visibility: 'hidden', padding: 0, width: 0 }}>Close</span>
                <button type="button" className="btn btn-outline-theme btn-full popup-close" onClick={this.handleClose}>Close</button>
              </div>
            ) : (
              <div className="content-footer">
                <button type="button" className="btn btn-outline-theme btn-full popup-close" onClick={this.handleClose}>Cancel</button>
                <a href={url} className={`btn btn-theme btn-full text-center exclude-exit-ramp-with-popup ${excludeIFrame ? 'exclude-iframe' : ''}`}>Continue</a>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default LeavingPopup;
