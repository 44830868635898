function getCookie(cname: string) {
  if (typeof document !== 'undefined') {
    const name = `${cname}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
  }
  return '';
}

function getCookieParam(param: any) {
  const vars:any = {};
  getCookie('wscrCookieConsent').replace(
    /([^=&]+)=([^&]*)/gi,
    // eslint-disable-next-line arrow-body-style
    (_m, key, value) => {
      // eslint-disable-next-line no-return-assign
      return (vars[key] = value);
    },
  );
  return vars[param];
}

function isGAEnabled() {
  return getCookieParam('3') === 'true';
}

function removeCookie(cname: string) {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  document.cookie = `${cname}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${_getDomain()};`;
}

function setCookie(cname: string, cvalue: string, exdays: number = 15) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  document.cookie = `${cname}=${cvalue}; expires=${d.toUTCString()}; path=/; domain=${_getDomain()};`;
}

// eslint-disable-next-line no-underscore-dangle

// eslint-disable-next-line no-underscore-dangle
function _getDomain() {
  // eslint-disable-next-line no-restricted-globals
  const parts = location.hostname.split('.');
  if (parts.length === 1) {
    // eslint-disable-next-line no-restricted-globals
    return location.hostname;
  }
  return `.${parts[parts.length - 2]}.${parts[parts.length - 1]}`;
}

export {
  getCookie,
  setCookie,
  getCookieParam,
  isGAEnabled,
  removeCookie,
};
