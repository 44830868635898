/**
 * Get site name.
 */
function getSiteName() {
  return 'AstraZeneca';
}

export function isOfflineApp() {
  return process.env.REACT_APP_OFFLINE === 'true';
}

export function isStaticBuildApp() {
  return process.env.REACT_APP_STATICBUILD === 'true';
}

export function getWindowPathName() {
  if (isOfflineApp()) {
    let locationPath = window.location.hash;
    locationPath = locationPath?.split('?')[0] || '';
    return locationPath;
  }
  return window.location.pathname;
}

export function getWindowLocationQuery() {
  if (isOfflineApp()) {
    const splits = window.location?.hash?.split('?') || [];
    let queryStr = '';
    if (splits.length >= 2) {
      /* eslint-disable prefer-destructuring */
      queryStr = `?${splits[1]}`;
    }
    return queryStr;
  }
  return window.location.search;
}

export default getSiteName;
