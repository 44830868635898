import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../assets/images/logo-astrazeneca.svg';

function Brand() {
  return (
    <Link to="/" className="nav-logo">
      <img src={logo} height="56px" width="224px" alt="AstraZeneca - Oncology Pipeline" />
    </Link>
  );
}

export default Brand;
