import React from 'react';
import ITargetCompound from '../../../../../interfaces/TargetCompound';
import MechanismDiseaseAction from './MechanismDiseaseAction/MechanismDiseaseAction';
import References from './References/References';

interface IProps {
  targetCompound: ITargetCompound, // Target/compound.
  defaultTab: boolean, // Is default tab
  active: boolean, // Is tab active
  onSelected: () => void,
}

function Overview(props: IProps) {
  const {
    targetCompound, active, defaultTab, onSelected,
  } = props;

  /**
   * Handle header click.
   */
  function handleHeaderClick() {
    onSelected();
  }

  return (
    <div className={`tab-content ${active || defaultTab ? 'active' : ''}`}>
      <div className={`tab-header collapse-action ${active || defaultTab ? 'active' : ''}`} onClick={handleHeaderClick}>
        <h2><strong>Overview</strong></h2>
        <em className="az-icon icon-arrow-right-white" />
      </div>
      <div className={`tab-content-body ${active || defaultTab ? 'active' : ''}`}>
        <div className="container">
          <h2 className="title">Target Overview</h2>
          <div dangerouslySetInnerHTML={{ __html: targetCompound.field_target_overview }} />

          <h2 className="title">Compound Overview</h2>
          <div dangerouslySetInnerHTML={{ __html: targetCompound.field_compound_overview }} />

          { targetCompound.field_mechanism_of_disease_media.length > 0 && (
            <MechanismDiseaseAction
              title="Mechanism of Disease"
              media={targetCompound.field_mechanism_of_disease_media}
              footnote={targetCompound.field_mechanism_of_disease_footnote}
            />
          )}

          { targetCompound.field_mechanism_of_action_media.length > 0 && (
            <MechanismDiseaseAction
              title="Mechanism of Action"
              media={targetCompound.field_mechanism_of_action_media}
              footnote={targetCompound.field_mechanism_of_action_footnote}
            />
          )}

          <References references={targetCompound.field_references} />
        </div>
      </div>
    </div>
  );
}

export default Overview;
