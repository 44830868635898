import React, {
  ChangeEvent, useEffect, useState, useContext, MouseEvent,
} from 'react';
import { useLocation, useParams } from 'react-router-dom';
import TargetCompoundList from './Partials/TargetCompoundList/TargetCompoundList';
import TumourType from '../../../store/TumourType';
import Info from './Partials/Info/Info';
import NotFound from '../NotFound/NotFound';
import getSiteName from '../../../helpers/Common';
import TargetCompound from '../../../store/TargetCompound';
import ITargetCompound from '../../../interfaces/TargetCompound';
import IFilterPhase from '../../../interfaces/FilterPhase';
import IFilterPhaseArray from '../../../interfaces/FilterPhaseArray';
import { arabicToRoman, romanToArabic } from '../../../helpers/Number';
import AppContext from '../../../context/AppContext';
import IApp from '../../../interfaces/App';
import MainContentWrapper from '../../Shared/MainContenWrapper/MainContentWrapper';

function TumourTypeDetail() {
  const location = useLocation();
  const locationState: any = location.state;
  const activePhases = locationState
    && locationState.activePhase ? locationState.activePhase.slice() : [];
  const { id } = useParams<{ id: string }>();
  const data: IApp = useContext(AppContext);
  const tumourTypeId = Number(id);
  const tumourType = TumourType.GetById(tumourTypeId);
  const tumorTargetCompound = TumourType.GetTargetCompounds(tumourTypeId);
  const [showFilter, setShowFilter] = useState(false);
  const [ShowFilterButtons, setShowFilterButtons] = useState(false);
  const [applyFilter, setApplyFilter] = useState(false);
  const [SelectedPhases, setSelectedPhases] = useState<string[]>(activePhases);
  const [phasesArray, setPhasesArray] = useState<IFilterPhaseArray[]>([]);
  const [filterTargetCompounds, setFilterTargetCompounds] = useState<ITargetCompound[]>([]);
  const mymap = new Map();
  const filterPhase: ITargetCompound | any = [];
  let foodBar: Array<any> = [];

  if (!tumourType) {
    return (<NotFound />);
  }

  // getting phases from array
  foodBar = tumorTargetCompound
    .map((i) => ({ id: i.id, phase: TargetCompound.GetPhasesArray(i) }));
  foodBar.filter((el) => {
    const min = el.phase.minRoman;
    const max = el.phase.maxRoman;
    const minVal = min && mymap.get(min);
    const maxVal = max && mymap.get(max);
    let demo = false;
    if (maxVal === undefined && max !== undefined) {
      const newPhase = TargetCompound.GetPhaseNumber(max);
      newPhase.map((j) => {
        if (j !== '') {
          mymap.set(j, el.id);
        }
        return false;
      });
      demo = true;
    }
    if (minVal === undefined && min !== undefined) {
      const newPhase = TargetCompound.GetPhaseNumber(min);
      newPhase.map((j) => {
        if (j !== '') {
          mymap.set(j, el.id);
        }
        return false;
      });
      demo = true;
    }
    return demo;
  });

  const mapKeys: string[] = [];
  const sortedMap = new Map();
  mymap.forEach((value, key) => mapKeys.push(key));
  let maxPhase: number = 0;
  for (let i = 0; i < mapKeys.length; i += 1) {
    const phaseKey: number = romanToArabic(mapKeys[i]);
    if (phaseKey > maxPhase) {
      maxPhase = phaseKey;
    }
  }
  for (let i = 0; i < maxPhase; i += 1) {
    const phaseKey: string = arabicToRoman(i + 1);
    if (mapKeys.indexOf(phaseKey) === -1) {
      mapKeys.push(phaseKey);
    }
  }
  mapKeys.sort().map((key) => sortedMap.set(key, mymap.get(key)));
  const mainStyle = tumourType.field_visual ? { backgroundImage: `url(${tumourType.field_visual})` } : {};

  /**
   * Handle click even on "Show filter" link.
   * @param event
   */
  const handleShowFilter = (event: any) => {
    event.preventDefault();
    setShowFilter(true);
  };

  /**
   * Handle click even on "close filter" link.
   * @param event
   */
  const handleClose = (event: any) => {
    event.preventDefault();
    if (SelectedPhases.length > 0) {
      setShowFilterButtons(true);
    } else {
      setShowFilterButtons(false);
    }
    setShowFilter(false);
  };

  /**
   * Handle click even on "select filter" link.
   * @param event
   */
  const handleFilterChange = (phase: string, event: ChangeEvent) => {
    // event.preventDefault();
    const { target } = event;
    const currentTarget = target as HTMLInputElement;
    const index = SelectedPhases.indexOf(phase);
    if (currentTarget.checked && index < 0) {
      SelectedPhases.push(phase);
      setSelectedPhases(SelectedPhases);
      currentTarget.checked = true;
    } else if (!currentTarget.checked && index >= 0) {
      SelectedPhases.splice(index, 1);
      setSelectedPhases(SelectedPhases);
      currentTarget.checked = false;
    }
    if (SelectedPhases.length > 0) {
      setShowFilterButtons(true);
    } else {
      setShowFilterButtons(false);
    }
  };

  // after Applying filter
  const handleApplyFilter = () => {
    tumorTargetCompound
      .map((t) => {
        const targetCompoundPhase: IFilterPhase | any = TargetCompound.GetPhasesArray(t);
        const min = targetCompoundPhase.minRoman;
        const max = targetCompoundPhase.maxRoman;
        let checkForMax: boolean = false;
        if (min) {
          const newPhase = TargetCompound.GetPhaseNumber(min);
          newPhase.map((j) => {
            if (j !== '') {
              for (let i = 0; i < SelectedPhases.length; i += 1) {
                if (!max && romanToArabic(SelectedPhases[i]) === romanToArabic(j)) {
                  filterPhase[t.id] = t;
                  return true;
                }
                if (max && romanToArabic(SelectedPhases[i]) >= romanToArabic(j)) {
                  checkForMax = true;
                  return false;
                }
              }
              return false;
            }
            return false;
          });
        }

        if (max) {
          const newPhase = TargetCompound.GetPhaseNumber(max);
          newPhase.map((j) => {
            if (j !== '') {
              for (let i = 0; i < SelectedPhases.length; i += 1) {
                if (!min && romanToArabic(SelectedPhases[i]) === romanToArabic(j)) {
                  filterPhase[t.id] = t;
                  return true;
                }
                if (min && checkForMax && romanToArabic(SelectedPhases[i]) <= romanToArabic(j)) {
                  filterPhase[t.id] = t;
                  return true;
                }
              }
              return false;
            }
            return false;
          });
        }
        return false;
      });
    if (SelectedPhases.length > 0) {
      const ordering = Number(data.field_respect_tc_ordering);
      if (ordering) {
        filterPhase
          .sort((a: any, b: any) => {
            if (a.label === b.label) {
              return a.field_compound_name.localeCompare(b.field_compound_name);
            }
            return a.label.localeCompare(b.label);
          });
      }
      setFilterTargetCompounds(filterPhase);
      setApplyFilter(true);
    } else {
      setFilterTargetCompounds(tumorTargetCompound);
      setApplyFilter(false);
    }
    setShowFilter(false);
  };

  // delete from filter
  const handleDeleteFromFilter = (event: MouseEvent, item: string) => {
    event.preventDefault();
    const index = SelectedPhases.indexOf(item);
    if (index >= 0) {
      // Delete selected tumor type and refresh CT list.
      SelectedPhases.splice(index, 1);
      setSelectedPhases(SelectedPhases);
      if (SelectedPhases.length > 0) {
        setFilterTargetCompounds(filterPhase);
      } else {
        setFilterTargetCompounds(tumorTargetCompound);
        setShowFilterButtons(false);
        setApplyFilter(false);
      }
    }
    handleApplyFilter();
  };

  const handleClear = () => {
    setSelectedPhases([]);
    setShowFilterButtons(false);
    setApplyFilter(false);
    setShowFilter(false);
    setFilterTargetCompounds(tumorTargetCompound);
    // setPhasesArray(Array.from(sortedMap, ([name, value]) => ({ phase: name, id: value })));
  };

  useEffect(() => {
    document.title = `${tumourType.label} | ${getSiteName()}`;
    if (SelectedPhases.length > 0) {
      setApplyFilter(true);
    }
    setPhasesArray(Array.from(sortedMap, ([name, value]) => ({ phase: name, id: value })));
    handleApplyFilter();
  }, []);

  return (
    <MainContentWrapper wrapperClass="bg-mask-wrapper" wrapperStyle={mainStyle}>
      <div className="inner-container">
        <div className="container">
          <Info tumourType={tumourType} />
          <div className="clear" />
          <div className="select-title-block">
            <div className="title-block">
              <div className="title">Select a mechanism below</div>
              <div className="filter-block">
                <a href="#" className={`btn btn-light btn-light2 btn-icon btn-sm popup-link ${applyFilter ? 'is-hide' : 'is-show'}`} onClick={handleShowFilter}>
                  Filter by Phase(s)
                  <em className="az-icon icon-filter" />
                </a>
                {/* eslint-disable-next-line */}
                <div tabIndex={0} className={`btn btn-light btn-light2 btn-sm filter-name active ${applyFilter ? 'is-show' : 'is-hide'}`} onClick={handleShowFilter}>
                  Filter by Phase(s)
                  <em className="az-icon icon-filter" />
                </div>
                <div className={`filter-select ${applyFilter && 'is-show'}`}>
                  {SelectedPhases.map((item) => (
                    <a href="#" key={item} className="btn btn-icon" onClick={(e) => handleDeleteFromFilter(e, item)}>
                      {item}
                      <em className="az-icon icon-close" />
                    </a>
                  ))}
                </div>
              </div>
            </div>
            {showFilter && (
              <div className="tab-content-popup filter-popup is-show">
                <a href="#" className={`close-popup ${SelectedPhases.length > 0 ? 'is-hide' : ''}`} onClick={handleClose}>
                  <em className="az-icon icon-close" />
                </a>
                <div className="popup-header">
                  <h2><strong>Select Phase(s)</strong></h2>
                  <div className="action-group">
                    <div className={`filter-action ${!ShowFilterButtons ? 'is-hide' : ''}`}>
                      <div className="filter-action-inner">
                        <button type="button" className="btn btn-icon clear-selection" onClick={handleClear}>
                          Clear selection
                          <em className="az-icon icon-close" />
                        </button>
                        <button type="button" className="btn btn-icon apply-filter" onClick={handleApplyFilter}>
                          Apply filters
                          <em className="az-icon icon-check" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="popup-body">
                  <div className="type-sub-link-block">
                    <ul className="type-sub-links">
                      {phasesArray.map((item, index) => (
                        <li key={`target_${item.id + index}`}>
                          <label className="check" htmlFor={`target_${item.id + index}`}>
                            <input type="checkbox" onChange={(e) => handleFilterChange(item.phase, e)} value={item.phase} defaultChecked={SelectedPhases.indexOf(item.phase) >= 0} id={`target_${item.id + index}`} />
                            <span className="phase-link">
                              Phase
                              {' '}
                              {item.phase}
                            </span>
                          </label>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>
          <TargetCompoundList mechanisms={filterTargetCompounds} selectedPhase={SelectedPhases} />
        </div>
      </div>
    </MainContentWrapper>
  );
}

export default TumourTypeDetail;
