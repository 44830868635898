import React, { MouseEvent } from 'react';
import ITargetCompound from '../../../../../interfaces/TargetCompound';
import IClinicalTrial from '../../../../../interfaces/ClinicalTrial';
import TargetCompound from '../../../../../store/TargetCompound';
import ListItem from './ListItem/ListItem';
import TumorSitesFilter from './TumorSitesFilter/TumorSitesFilter';
import Overview from './Overview/Overview';
import AppContext from '../../../../../context/AppContext';
import { getWindowLocationQuery, getWindowPathName } from '../../../../../helpers/Common';

interface IProps {
  targetCompound: ITargetCompound, // Target/compound.
  activeAOI: number[], // Areas Under Investigation.
  activePhases: string[], // selected phases
  active: boolean, // Is tab active
  onSelected: () => void,
}

interface IState {
  allClinicalTrials: IClinicalTrial[], // All available clinical trials.
  selectedClinicalTrial: IClinicalTrial | null, // Selected Clinical Trial.
  showFilter: boolean, // Show filter or not.
  tumorSiteIds: number[], // Selected tumor sites.
  phaseIds: string[], // selected phases
}

class ClinicalTrials extends React.Component<IProps, IState> {
  // eslint-disable-next-line react/static-property-placement
  static contextType = AppContext;

  constructor(props: IProps) {
    super(props);
    const usedActiveAOI = props.activeAOI.filter((aoi) => this.getTumorSiteName(aoi));
    const usedActivePhases = props.activePhases;
    this.state = {
      allClinicalTrials: [],
      selectedClinicalTrial: null,
      showFilter: false,
      tumorSiteIds: usedActiveAOI,
      phaseIds: usedActivePhases,
    };
    this.handleShowFilter = this.handleShowFilter.bind(this);
    this.trialUnselected = this.trialUnselected.bind(this);
    this.handleHeaderClick = this.handleHeaderClick.bind(this);
  }

  componentDidMount() {
    this.fetchCTs();
    this.setClinicalTrialBasedOnQuery();
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    const { active, activeAOI, targetCompound } = this.props;
    if (prevProps.active && !active) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        selectedClinicalTrial: null,
      });
    }

    if (prevProps.active !== active || prevProps.targetCompound !== targetCompound) {
      const usedActiveAOI = activeAOI.filter((aoi) => this.getTumorSiteName(aoi));
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        tumorSiteIds: usedActiveAOI,
      });
      setTimeout(() => {
        this.fetchCTs();
        this.setClinicalTrialBasedOnQuery();
      }, 500);
    }
    this.setClinicalTrialBasedOnQuery();
  }

  /**
   * Handle click even on "Show filter" link.
   * @param event
   */
  handleShowFilter(event: MouseEvent) {
    event.preventDefault();
    this.setState({
      showFilter: true,
    });
  }

  /**
   * Handle click event on link to remove selected tumor site.
   *
   * @param tumorSiteId
   * @param event
   */
  handleDeleteFromFilter(tumorSiteId: number, event: MouseEvent) {
    event.preventDefault();
    const { tumorSiteIds } = this.state;
    const index = tumorSiteIds.indexOf(tumorSiteId);
    if (index >= 0) {
      // Delete selected tumor type and refresh CT list.
      tumorSiteIds.splice(index, 1);
      this.fetchCTs();
    }
  }

  /**
   * Handle click event on link to remove selected phase.
   *
   * @param phaseId
   * @param event
   */
  handleDeletePhaseFromFilter(phaseId: string, event: MouseEvent) {
    event.preventDefault();
    const { phaseIds } = this.state;
    const index = phaseIds.indexOf(phaseId);
    if (index >= 0) {
      // Delete selected tumor type and refresh CT list.
      phaseIds.splice(index, 1);
      this.fetchCTs();
    }
  }

  /**
   * Callback on header click event.
   *
   */
  handleHeaderClick(event: MouseEvent) {
    const { onSelected } = this.props;
    const { target } = event;
    const elTarget = target as HTMLElement;
    onSelected();
    setTimeout(() => {
      window.scrollTo({
        top: elTarget.offsetTop - 10,
        behavior: 'smooth',
      });
    });
  }

  getClinicalTrials(areas: number[] = [], phase: string[] = []): IClinicalTrial[] {
    const { targetCompound } = this.props;
    // eslint-disable-next-line camelcase
    const { field_respect_ct_ordering } = this.context;
    const cts: IClinicalTrial[] = TargetCompound.GetClinicalTrials(
      targetCompound,
      areas,
      phase,
      Boolean(field_respect_ct_ordering),
    );
    return cts;
  }

  setClinicalTrialBasedOnQuery() {
    const query = new URLSearchParams(getWindowLocationQuery());
    const trial = query.get('trial');
    const { selectedClinicalTrial } = this.state;
    if (trial && (!selectedClinicalTrial || selectedClinicalTrial.id.toString() !== trial)) {
      const cts = this.getClinicalTrials();
      const trialId = Number(trial);
      const clinicalTrial = cts.find((item) => Number(item.id) === trialId);
      if (clinicalTrial && selectedClinicalTrial !== clinicalTrial) {
        setTimeout(() => {
          this.setState({
            selectedClinicalTrial: clinicalTrial,
          });
        });
      }
    }
  }

  /**
   * Get tumor site by ID.
   *
   * @param id
   */
  getTumorSiteName(id: number) {
    const { targetCompound } = this.props;
    const tumorSite = targetCompound.field_areas_under_investigation
      .find((ts) => Number(ts.id) === id);

    return tumorSite ? tumorSite.label : '';
  }

  /**
   * Callback trial selected event.
   *
   * @param clinicalTrial
   */
  trialSelected(clinicalTrial: IClinicalTrial) {
    this.setState({
      selectedClinicalTrial: clinicalTrial,
    });
    window.history.replaceState(null, document.title, `${getWindowPathName()}?tab=clinical-trials&trial=${clinicalTrial.id}`);
  }

  /**
   * Callback on filter closed event.
   *
   * @param tumorSiteIds
   */
  filterClosed(tumorSiteIds: number[], phaseIds: string[]) {
    // Set selected tumor types and hide filter.
    this.setState({
      tumorSiteIds,
      phaseIds,
      showFilter: false,
    });
    // Refresh CT list.
    setTimeout(() => {
      this.fetchCTs();
    });
  }

  /**
   * Trial unselected event callback.
   */
  trialUnselected() {
    this.setState({
      selectedClinicalTrial: null,
    });
    window.history.replaceState(null, document.title, `${getWindowPathName()}?tab=clinical-trials`);
  }

  /**
   * Fetch CTs and set into state.
   */
  fetchCTs() {
    const { tumorSiteIds, phaseIds } = this.state;
    const cts = this.getClinicalTrials(tumorSiteIds, phaseIds);

    this.setState({
      allClinicalTrials: cts,
    });
  }

  render() {
    const { targetCompound, active } = this.props;
    const {
      allClinicalTrials,
      selectedClinicalTrial,
      showFilter,
      tumorSiteIds,
      phaseIds,
    } = this.state;

    return (
      <div className={`tab-content ${active ? 'active' : ''}`}>
        <div className={`tab-header collapse-action ${active ? 'active' : ''}`} onClick={this.handleHeaderClick}>
          <h2><strong>Clinical Trials</strong></h2>
          <em className="az-icon icon-arrow-right-white" />
        </div>
        <div className={`tab-content-body ${active ? 'active' : ''}`}>
          <div className="container">
            { !selectedClinicalTrial && (
              <div className="clinical-trial-block">
                <div className="tab-content-title grid-col-2">
                  <div>
                    <h2><strong>Clinical trial information</strong></h2>
                    <p>Select trial for more information</p>
                  </div>
                  <div className="action-btn align-self-end">
                    <a href="#" className={`btn btn-light btn-light2 btn-icon btn-sm target-popup-link ${tumorSiteIds.length > 0 || phaseIds.length > 0 ? 'active' : ''}`} onClick={this.handleShowFilter}>
                      Filter
                      {' '}
                      <em className="az-icon icon-filter" />
                    </a>
                  </div>
                </div>

                { (tumorSiteIds.length > 0 || phaseIds.length > 0) && (
                  <div className="filter-select is-show">
                    { tumorSiteIds.map((tsid) => (
                      <a key={tsid} href="#" className="btn btn-icon" onClick={this.handleDeleteFromFilter.bind(this, tsid)}>
                        { this.getTumorSiteName(tsid) }
                        <em className="az-icon icon-close" />
                      </a>
                    ))}
                    { phaseIds.map((phsid) => (
                      <a key={phsid} href="#" className="btn btn-icon" onClick={this.handleDeletePhaseFromFilter.bind(this, phsid)}>
                        Phase
                        {' '}
                        {phsid}
                        <em className="az-icon icon-close" />
                      </a>
                    ))}
                  </div>
                )}

                <div className="tab-type-action-card clinical-data-block">
                  <ul className="type-sub-card type-sub-card-dark">
                    { allClinicalTrials.map((trial) => (
                      <li key={trial.id}>
                        <ListItem
                          clinicalTrial={trial}
                          onTrialSelected={(ct) => this.trialSelected(ct)}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
                { showFilter && (
                  <TumorSitesFilter
                    activeTumorSites={tumorSiteIds}
                    activePhases={phaseIds}
                    tumorSites={targetCompound.field_areas_under_investigation}
                    onFilterClosed={(tsids, phaseids) => this.filterClosed(tsids, phaseids)}
                  />
                )}
              </div>
            )}
            { selectedClinicalTrial && (
              <Overview
                clinicalTrial={selectedClinicalTrial}
                onClosed={() => this.trialUnselected()}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ClinicalTrials;
