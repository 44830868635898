import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import getSiteName from '../../../helpers/Common';
import Info from '../../Shared/Info/Info';
import NotFound from '../NotFound/NotFound';
import TumourType from '../../../store/TumourType';
import ContentAccordion from './Partials/ContentAccordion/ContentAccordion';
import MainContentWrapper from '../../Shared/MainContenWrapper/MainContentWrapper';

function Narrative() {
  useEffect(() => {
    document.title = `Narrative | ${getSiteName()}`;
  }, []);

  const { id } = useParams<{ id: string }>();
  const narrative = TumourType.GetNarrative(Number(id));
  if (!narrative) {
    return (<NotFound />);
  }

  const tumourType = TumourType.GetById(Number(narrative.tumour_type));
  if (!tumourType) {
    return (<NotFound />);
  }

  const mainStyle = tumourType.field_visual ? { backgroundImage: `url(${tumourType.field_visual})` } : {};
  const title = `${tumourType.label} ${tumourType.label.toLowerCase() === 'lung' ? 'Cancer Science' : 'Narrative'}`;
  return (
    <MainContentWrapper wrapperClass="bg-wrapper" wrapperStyle={mainStyle}>
      <div className="inner-container">
        <div className="container">
          <Info
            title={title}
            backUrl={`/tumour-type/${tumourType.id}`}
            description={narrative.body}
          />
          <div className="clear" />
        </div>
        <ContentAccordion items={narrative.content} />
      </div>
    </MainContentWrapper>
  );
}

export default Narrative;
