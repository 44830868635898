import React, { MouseEvent } from 'react';
import IClinicalTrial from '../../../../../../interfaces/ClinicalTrial';
import { getWindowPathName } from '../../../../../../helpers/Common';

interface IProps {
  clinicalTrial: IClinicalTrial, // Clinical Trial
  // eslint-disable-next-line no-unused-vars
  onTrialSelected: (clinicalTrial: IClinicalTrial) => void // Trial select event callback.
}

interface IState {}

class ListItem extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.handleTrialClick = this.handleTrialClick.bind(this);
  }

  /**
   * Handle CT click event.
   *
   * @param event
   */
  handleTrialClick(event: MouseEvent) {
    event.preventDefault();
    const { clinicalTrial, onTrialSelected } = this.props;
    onTrialSelected(clinicalTrial);
  }

  render() {
    const { clinicalTrial } = this.props;

    return (
      <a href={`${getWindowPathName()}?tab=clinical-trials&trial=${clinicalTrial.id}`} className="card-block target-card-link" onClick={this.handleTrialClick}>
        <div className="card-head"><h3><strong>{ clinicalTrial.label }</strong></h3></div>
        <div className="card-body">
          <ul className="list-info">
            <li>
              <strong>Tumor</strong>
              {' '}
              <span>{ clinicalTrial.field_tumour }</span>
            </li>
            {clinicalTrial.field_regimen && (
              <li>
                <strong>Regimen</strong>
                {' '}
                <span>{ clinicalTrial.field_regimen }</span>
              </li>
            ) }
            <li>
              <strong>Phase</strong>
              {' '}
              <span>{ clinicalTrial.field_phase.label }</span>
            </li>
          </ul>
          <div className="card-note">
            <small>{ clinicalTrial.field_footnotes }</small>
          </div>
        </div>
      </a>
    );
  }
}

export default ListItem;
