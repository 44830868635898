import { useContext } from 'react';
import AppContext from '../context/AppContext';
import IApp from '../interfaces/App';
import ITargetCompound from '../interfaces/TargetCompound';
import ITumourType from '../interfaces/TumourType';
import INarrative from '../interfaces/Narrative';

export default {
  // Get all tumour types.
  GetAll: (): ITumourType[] => {
    const data: IApp = useContext(AppContext);

    if (!data || !data.field_tumour_type) {
      return [];
    }

    return data.field_tumour_type;
  },

  // Get tumour type by ID.
  GetById: (id: number): ITumourType | null => {
    const data: IApp = useContext(AppContext);

    if (!data || !data.field_tumour_type) {
      return null;
    }

    const ttid = Number(id);

    return data.field_tumour_type
      .find((tumour) => Number(tumour.id) === ttid) || null;
  },

  // Get all target/compounds from tumour type ID.
  GetTargetCompounds: (id: number): ITargetCompound[] => {
    const data: IApp = useContext(AppContext);
    const ordering = Number(data.field_respect_tc_ordering);

    if (!data || !data.field_tumour_type) {
      return [];
    }

    const ttid = Number(id);

    const tumourType = data.field_tumour_type.find((tumour) => Number(tumour.id) === ttid);

    const compounds = tumourType ? tumourType.field_targets_compounds : [];

    if (ordering) {
      compounds
        .sort((a, b) => {
          if (a.label === b.label) {
            return a.field_compound_name.localeCompare(b.field_compound_name);
          }
          return a.label.localeCompare(b.label);
        });
    }

    return compounds;
  },

  // Get narrative related to tumour type.
  GetNarrative: (id: number): INarrative | null => {
    const data: IApp = useContext(AppContext);
    if (!data || !data.narratives) {
      return null;
    }

    return data.narratives.find((item) => Number(item.tumour_type) === id) || null;
  },
};
