import React, {
  MouseEvent,
  useContext,
  useEffect,
  useState,
} from 'react';
import Overview from '../../../../../../../TargetCompound/Partials/ClinicalTrials/Overview/Overview';
import INarrativeAUISItem from '../../../../../../../../../interfaces/NarrativeAUISItem';
import INarrativeCT from '../../../../../../../../../interfaces/NarrativeCT';
import AssociatedArea from '../../../../../../../../../store/AssociatedArea';
import IMechanism from '../../../../../../../../../interfaces/Mechanism';
import IApp from '../../../../../../../../../interfaces/App';
import AppContext from '../../../../../../../../../context/AppContext';
import IClinicalTrial from '../../../../../../../../../interfaces/ClinicalTrial';
import { getWindowPathName } from '../../../../../../../../../helpers/Common';

interface IProps {
  auis: INarrativeAUISItem[],
}

function ClinicalTrials(props: IProps) {
  const data: IApp = useContext(AppContext);
  const { auis } = props;
  // const { id } = useParams<{ id: string }>();
  const [clinicalTrial, setClinicalTrial] = useState<IClinicalTrial | null>(null);
  const [selectedKeys, setSelectedKeys] = useState<IMechanism[]>([]);
  const allMechanisms: IMechanism[] = AssociatedArea.GetAll();

  function GetClinicalFromId(ctid: number, auisId: number): IClinicalTrial | null {
    let curCT = null;
    if (data && data.field_tumour_type) {
      data.field_tumour_type.forEach((ftt) => {
        ftt.field_targets_compounds.forEach((ftc) => {
          ftc.field_areas_under_investigation.forEach((faui) => {
            if (!auisId || faui.id === auisId) {
              faui.field_clinical_trials.forEach((ct) => {
                if (ct.id === ctid) {
                  curCT = ct;
                }
              });
            }
          });
        });
      });
    }
    return curCT;
  }

  const trialClick = (trial: INarrativeCT, auisId: number, event: MouseEvent) => {
    event.preventDefault();
    const curTrial = GetClinicalFromId(trial.id, auisId);
    if (curTrial) {
      setClinicalTrial(curTrial);
      document.body.classList.add('is-model');
    }
  };

  const trialClosed = () => {
    setClinicalTrial(null);
    document.body.classList.remove('is-model');
    document.body.classList.add('is-animated');
    window.history.replaceState(null, document.title, `${getWindowPathName()}`);
  };

  const fetchKeys = () => {
    const keys: IMechanism[] = [];
    auis.forEach((rowItem: any) => {
      rowItem.stages.forEach((stage: any) => {
        stage.cells.forEach((cell: any) => {
          if (keys && keys.filter((item) => item.id === cell.associated_area_id).length === 0) {
            const assoArea = allMechanisms.filter((item) => item.id === cell.associated_area_id);
            if (assoArea && assoArea.length > 0) {
              keys.push(assoArea[0]);
            }
          }
        });
      });
    });
    keys.sort((a, b) => (a.order > b.order ? 1 : -1));
    setSelectedKeys(keys);
  };

  const getMaxStagesInRow = () => {
    let maxStages = 0;
    auis.forEach((rowItem: any) => {
      if (maxStages < rowItem.stages.length) {
        maxStages = rowItem.stages.length;
      }
    });
    return maxStages;
  };

  /* const getMaxCTColInStages = () => {
    let maxCTCols = 0;
    auis.forEach((rowItem: any) => {
      rowItem.stages.forEach((stage: any) => {
        if (maxCTCols < stage.cells.length) {
          maxCTCols = stage.cells.length;
        }
      });
    });
    return maxCTCols;
  }; */

  const makeSameHeightTitle = (resize: any) => {
    if (auis) {
      for (let row = 0; row < auis.length; row += 1) {
        const cWidth = document.documentElement.clientWidth;
        const elements = document.getElementsByClassName(`equalHeight${row}`);
        if (elements) {
          const allHeights = [];
          let i = 0;
          if (resize === true) {
            for (i = 0; i < elements.length; i += 1) {
              (elements[i] as HTMLElement).style.minHeight = 'auto';
            }
          }

          for (i = 0; i < elements.length; i += 1) {
            const elementHeight = elements[i].clientHeight;
            allHeights.push(elementHeight);
          }

          for (i = 0; i < elements.length; i += 1) {
            if (cWidth > 991) {
              const maxHeight = Math.max(...allHeights);
              (elements[i] as HTMLElement).style.minHeight = `${maxHeight}px`;
            } else {
              (elements[i] as HTMLElement).style.minHeight = 'auto';
            }
            // if(resize === false){
            // elements[i].className = elements[i].className + " eh-show";
            // }
          }
        }
      }
    }
  };

  const handleResize = () => {
    makeSameHeightTitle(true);
  };

  useEffect(() => {
    fetchKeys();
    makeSameHeightTitle(false);
    window.addEventListener('resize', handleResize);
    return () => {
      document.body.classList.remove('is-animated');
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const maxStages = getMaxStagesInRow();
  /* let maxCTCols = getMaxCTColInStages();
  if (maxStages === 1 && maxCTCols <= 3) {
    maxCTCols = 3;
  } else {
    maxCTCols = Math.floor(12 / maxCTCols);
  } */
  const colStyle = { '--col-repeat': maxStages } as React.CSSProperties;
  return (
    <>
      <div className="type-card-blocks">
        {auis && auis.map((rowItem: any, index) => (
          <>
            <div className="row-type-sub-card row-type-sub-card-col-repeat" style={colStyle}>
              <div className="row-type-sub-card-lable-v2 align-self-center">
                <h2>
                  <strong>{ rowItem.title }</strong>
                </h2>
              </div>
              {rowItem.stages.map((stage: any, indexCol: number) => (
                <div className={`row-type-sub-card-col ${indexCol !== 0 ? 'row-md-border-left' : ''} ${index !== 0 && index === auis.length - 1 ? 'row-type-sub-card-col-bottom-title' : ''}`}>
                  { !!stage.title && (index === 0 || index < auis.length - 1) && (
                    <h2 className={`text-center equalHeight${index}`}>
                      <strong dangerouslySetInnerHTML={{ __html: stage.title }} />
                    </h2>
                  )}
                  <div className="row gutters-5">
                    {stage.cells && stage.cells.map((cell: any) => {
                      const keyColor = selectedKeys
                        .filter((keyItem) => keyItem.id === cell.associated_area_id);
                      let curStyle = {};
                      if (keyColor && keyColor.length > 0) {
                        curStyle = { '--key-color': `${keyColor[0].field_color}70`, '--key-bcolor': `${keyColor[0].field_color}30` } as React.CSSProperties;
                      }

                      return (
                        <div className={`col-6 col-md-6 col-lg-6 col-xl-${12 / stage.cells.length}`}>
                          <ul className="type-sub-card type-sub-card-lists">
                            { cell.clinical_trials.map((trial: INarrativeCT) => (
                              <li key={trial.id}>
                                <a href="#" className="card-block key-color" style={curStyle} onClick={(e) => trialClick(trial, rowItem.id, e)}>
                                  <div className="card-head"><h3><strong dangerouslySetInnerHTML={{ __html: trial.title }} /></h3></div>
                                  <div className="card-body">
                                    <div className="card-body-text" dangerouslySetInnerHTML={{ __html: trial.field_regimen_text }} />
                                  </div>
                                </a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      );
                    })}
                  </div>
                  { !!stage.title && index !== 0 && index === auis.length - 1 && (
                    <h2 className="text-center mt-30">
                      <strong dangerouslySetInnerHTML={{ __html: stage.title }} />
                    </h2>
                  )}
                </div>
              ))}
            </div>
            { index < auis.length - 1 && (
              <div className="hr-full" />
            )}
          </>
        ))}
      </div>
      <div className="key-v2">
        <strong>Key</strong>
        <div className="key-lists">
          {selectedKeys.map((item) => (
            <div>
              <span className="legend key1" style={{ background: `${item.field_color}70` }} />
              {item.label}
            </div>
          ))}
        </div>
      </div>
      { clinicalTrial && (
        <div className="custom-modal is-show">
          <div className="wrapper">
            <div className="content">
              <div className="more-info-block-model">
                <Overview clinicalTrial={clinicalTrial} onClosed={trialClosed} light />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ClinicalTrials;
