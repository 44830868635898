import React, { ChangeEvent, MouseEvent } from 'react';
import IAreaUnderInvestigation from '../../../../../../interfaces/AreaUnderInvestigation';
import { romanToArabic } from '../../../../../../helpers/Number';

interface IProps {
  activeTumorSites: number[], // Active tumor site Ids.
  activePhases: string[], // Active phases.
  tumorSites: IAreaUnderInvestigation[], // Area Under Investigations.
  // eslint-disable-next-line no-unused-vars
  onFilterClosed: (selectedTumorSiteIds: number[], selectedPhases: string[]) => void
}

interface IState {
  selectedTumorSiteIds: number[] // Selected tumor sites.
  selectedPhases: string[],
  phaseArray: string[]
}

class TumorSitesFilter extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      selectedTumorSiteIds: props.activeTumorSites,
      selectedPhases: props.activePhases,
      phaseArray: [],
    };
    this.handleClear = this.handleClear.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    const { tumorSites } = this.props;
    const mapKeys: string[] = [];
    tumorSites.forEach((t) => {
      if (t.field_clinical_trials) {
        t.field_clinical_trials.forEach((trial) => {
          if (!mapKeys.includes(trial.field_phase.number)) {
            mapKeys.push(trial.field_phase.number);
          }
        });
      }
    });
    mapKeys.sort((a, b) => (romanToArabic(a) > romanToArabic(b) ? 1 : -1));
    this.setState({
      phaseArray: mapKeys,
    });
  }

  /**
   * Handle tumor site select event.
   *
   * @param id
   * @param event
   */
  handleTumorSiteSelect(id: number, event: ChangeEvent) {
    const { target } = event;
    const currentTarget = target as HTMLInputElement;
    const { selectedTumorSiteIds } = this.state;
    const tsid = Number(id);
    const index = selectedTumorSiteIds.indexOf(tsid);
    if (currentTarget.checked && index < 0) {
      selectedTumorSiteIds.push(Number(id));
    } else if (!currentTarget.checked && index >= 0) {
      selectedTumorSiteIds.splice(index, 1);
    }
    this.setState({
      selectedTumorSiteIds,
    });
  }

  handlePhaseSelect(phase: string, event: ChangeEvent) {
    const { target } = event;
    const currentTarget = target as HTMLInputElement;
    const { selectedPhases } = this.state;
    const index = selectedPhases.indexOf(phase);
    if (currentTarget.checked && index < 0) {
      selectedPhases.push(phase);
    } else if (!currentTarget.checked && index >= 0) {
      selectedPhases.splice(index, 1);
    }
    this.setState({
      selectedPhases,
    });
  }

  /**
   * Handle clear selection event.
   */
  handleClear() {
    this.setState({
      selectedTumorSiteIds: [],
      selectedPhases: [],
    });
  }

  /**
   * Handle close filter event.
   * @param event
   */
  handleClose(event: MouseEvent) {
    event.preventDefault();
    const { onFilterClosed } = this.props;
    const { selectedTumorSiteIds, selectedPhases } = this.state;
    onFilterClosed(selectedTumorSiteIds, selectedPhases);
  }

  render() {
    const { tumorSites } = this.props;
    const { selectedTumorSiteIds, selectedPhases, phaseArray } = this.state;
    return (
      <div className="tab-content-popup filter-type-popup is-show">
        <div className="popup-header">
          <h2><strong>Select tumor site(s)</strong></h2>
          <div className="action-group">
            <a href="#" className={`close-popup ${selectedTumorSiteIds.length > 0 || selectedPhases.length > 0 ? 'is-hide' : ''}`} onClick={this.handleClose}>
              <em className="az-icon icon-close" />
            </a>
            <div className={`filter-action ${selectedTumorSiteIds.length > 0 || selectedPhases.length > 0 ? '' : 'is-hide'}`}>
              <div className="filter-action-inner">
                <button type="button" className="btn btn-icon clear-selection" onClick={this.handleClear}>
                  Clear selection
                  {' '}
                  <em className="az-icon icon-close" />
                </button>
                <button type="button" className="btn btn-icon apply-filter" onClick={this.handleClose}>
                  Apply filters
                  {' '}
                  <em className="az-icon icon-check" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="popup-body is-scrolling">
          <div className="type-sub-link-block">
            <ul className="type-sub-links">
              { tumorSites.map((tumorSite) => (
                <li key={tumorSite.id}>
                  <label className="check" htmlFor={`target_${tumorSite.id}`}>
                    <input
                      type="checkbox"
                      id={`target_${tumorSite.id}`}
                      checked={selectedTumorSiteIds.indexOf(Number(tumorSite.id)) >= 0}
                      onChange={this.handleTumorSiteSelect.bind(this, tumorSite.id)}
                    />
                    <span className="target-link">
                      <h3 title={tumorSite.label}><strong>{ tumorSite.label }</strong></h3>
                      { tumorSite.field_icon && (
                        <span>
                          <img src={tumorSite.field_icon} alt={tumorSite.label} className="inline-svg" />
                        </span>
                      )}
                    </span>
                  </label>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <hr />
        <div className="popup-header">
          <h2><strong>Select phase(s)</strong></h2>
        </div>
        <div className="popup-body is-scrolling">
          <div className="type-sub-link-block">
            <ul className="type-sub-links">
              { phaseArray.map((phase) => (
                <li key={phase}>
                  <label className="check" htmlFor={`target_${phase}`}>
                    <input
                      type="checkbox"
                      id={`target_${phase}`}
                      checked={selectedPhases.indexOf(phase) >= 0}
                      onChange={this.handlePhaseSelect.bind(this, phase)}
                    />
                    <span className="target-link">
                      <h3 title={phase}>
                        <strong>
                          Phase
                          {' '}
                          {phase}
                        </strong>
                      </h3>
                    </span>
                  </label>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default TumorSitesFilter;
