/**
 * Split string and get first word or rest string except first word.
 *
 * @param str
 * @param first
 */
function splitString(str: string, first = true): string {
  const arr = str.split(' ');

  if (first) {
    return arr[0];
  }

  arr.shift();

  return arr ? arr.join(' ') : '';
}

export default splitString;
