import Home from './components/Pages/Home/Home';
import TumourType from './components/Pages/TumourType/TumourType';
import TumourTypeDetail from './components/Pages/TumourTypeDetail/TumourTypeDetail';
import TargetCompound from './components/Pages/TargetCompound/TargetCompound';
import Glossary from './components/Pages/Glossary/Glossary';
import ScientificPillars from './components/Pages/ScientificPillars/ScientificPillars';
import CellPathway from './components/Pages/CellPathway/CellPathway';
import CellOverview from './components/Pages/CellOverview/CellOverview';
import DDROverview from './components/Pages/DDROverview/DDROverview';
import Narrative from './components/Pages/Narrative/Narrative';

const routes = [
  {
    path: '/',
    component: Home,
  },
  {
    path: '/tumour-type',
    component: TumourType,
  },
  {
    path: '/tumour-type/:id',
    component: TumourTypeDetail,
  },
  {
    path: '/tumour-type/:ttid/target-compound/:tcid',
    component: TargetCompound,
  },
  {
    path: '/glossary',
    component: Glossary,
  },
  {
    path: '/scientific-pillars',
    component: ScientificPillars,
  },
  {
    path: '/full-pipeline',
    component: ScientificPillars,
  },
  {
    path: '/scientific-pillars/type/:ttid/target/:tcid',
    component: TargetCompound,
  },
  {
    path: '/cell-pathway',
    component: CellPathway,
  },
  {
    path: '/cell-pathway/ddr-overview',
    component: DDROverview,
  },
  {
    path: '/cell-pathway/:id',
    component: CellOverview,
  },
  {
    path: '/tumour-type/:id/narrative',
    component: Narrative,
  },
];

export default routes;
