import React from 'react';
import { Link } from 'react-router-dom';

interface IProps {
  title: string // Title.
  description: string // Description
}

function Info(props: IProps) {
  const { title, description } = props;
  return (
    <div className="type-innerpage-title ddr-overview-page-title">
      <div className="back-action">
        <Link to="/cell-pathway" className="btn-back btn-box ml-0">
          <em className="az-icon icon-arrow-left" />
          BACK
        </Link>
      </div>
      <div className="bg-content-block">
        <h1 className="active">
          <strong>{ title }</strong>
        </h1>
        { !!description && (
          <p className="active" dangerouslySetInnerHTML={{ __html: description }} />
        )}
      </div>
    </div>
  );
}

export default Info;
