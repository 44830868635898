import React, { useEffect } from 'react';
import TumourTypeList from './Partials/TumourTypeList/TumourTypeList';
import bgUrl from '../../../assets/images/type-bg-2.webp';
import Info from './Partials/Info/Info';
import getSiteName from '../../../helpers/Common';
import MainContentWrapper from '../../Shared/MainContenWrapper/MainContentWrapper';

function TumourType() {
  useEffect(() => {
    document.title = `Tumour Type | ${getSiteName()}`;
  }, []);

  const mainStyle = {
    backgroundImage: `url(${bgUrl})`,
  };

  return (
    <MainContentWrapper wrapperClass="is-bg-ani is-bg-right" wrapperStyle={mainStyle} animateBodyImg>
      <div className="inner-container">
        <div className="container">
          <Info />
          <div className="clear" />
          <div className="type-sub-link-block">
            <TumourTypeList />
          </div>
        </div>
      </div>
    </MainContentWrapper>
  );
}

export default TumourType;
