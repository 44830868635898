import React, { useEffect } from 'react';
import logo from '../../../assets/images/logo-astrazeneca.svg';
import icon403 from '../../../assets/images/error/icon-access-denied.svg';
import getSiteName from '../../../helpers/Common';

function AccessDenied() {
  useEffect(() => {
    document.title = `Access denied | ${getSiteName()}`;
  }, []);

  return (
    <>
      <header className="main-header">
        <div className="container">
          <nav className="navbar">
            <span className="nav-logo">
              <img src={logo} height="56px" width="224px" alt="AstraZeneca - Oncology Pipeline" />
            </span>
          </nav>
        </div>
      </header>
      <main className="main-content-wrapper server-wrapper">
        <div className="server-error-block">
          <div className="container">
            <div className="server-error-card">
              <div className="content">
                <div className="img">
                  <img src={icon403} alt="Access Denied" />
                </div>
                <h1><strong>Access denied</strong></h1>
              </div>
            </div>
          </div>
        </div>
      </main>
      <div className="bubble-wrapper">
        { Array.from(Array(40).keys()).map((i) => (
          <div className={`bubble bubble-${i + 1}`} key={i} />
        ))}
      </div>
    </>
  );
}

export default AccessDenied;
