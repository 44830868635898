import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
// import bg from '../../../assets/images/cell-bg.webp';
import Welcome from '../../Shared/Welcome/Welcome';
// import Navigation from '../../Shared/Navigation/Navigation';
import CellType from '../../../store/CellType';
import getSiteName from '../../../helpers/Common';
import MainContentWrapper from '../../Shared/MainContenWrapper/MainContentWrapper';
import tumerCellDImg from '../../../assets/images/cell-pathway/d-tumor-cell.png';
import tumerCellMImg from '../../../assets/images/cell-pathway/m-tumor-cell.png';
import bCellDImg from '../../../assets/images/cell-pathway/d-b-cell.png';
import bCellMImg from '../../../assets/images/cell-pathway/m-b-cell.png';
import tCellDImg from '../../../assets/images/cell-pathway/d-t-cell.png';
import tCellMImg from '../../../assets/images/cell-pathway/m-t-cell.png';
import dendriticCellDImg from '../../../assets/images/cell-pathway/d-dendritic-cell.png';
import dendriticCellMImg from '../../../assets/images/cell-pathway/m-dendritic-cell.png';
import ddrOverviewDImg from '../../../assets/images/cell-pathway/d-ddr-overview.png';
import ddrOverviewCellMImg from '../../../assets/images/cell-pathway/m-ddr-overview.png';
import cellCircleImg from '../../../assets/images/cell-pathway/cell-cricle.svg';

function CellPathway() {
  useEffect(() => {
    document.title = `Cell Pathway | ${getSiteName()}`;
  }, []);

  // const mainStyle = {
  //  backgroundImage: `url(${bg})`,
  // };

  const getCellPrefix = (name: string) => {
    let cellName = name.toLowerCase();
    if (cellName.includes('cell')) {
      cellName = cellName.substr(0, cellName.length - 5);
    }
    return cellName;
  };

  const getCellImage = (name: string, isMobile: boolean) => {
    const cellName = getCellPrefix(name);

    switch (cellName) {
      case 'tumor':
        return isMobile ? tumerCellMImg : tumerCellDImg;
      case 'b':
        return isMobile ? bCellMImg : bCellDImg;
      case 't':
        return isMobile ? tCellMImg : tCellDImg;
      case 'dendritic':
        return isMobile ? dendriticCellMImg : dendriticCellDImg;
      case 'ddr overview':
        return isMobile ? ddrOverviewCellMImg : ddrOverviewDImg;
      default:
        return undefined;
    }
  };

  const cellTypes = CellType.GetAll();
  const navLinks = cellTypes.map((cell) => ({
    url: `/cell-pathway/${cell.id}`,
    label: cell.title,
    dImg: getCellImage(cell.title, false),
    mImg: getCellImage(cell.title, true),
  }));
  const ddrOverview = {
    url: '/cell-pathway/ddr-overview',
    label: 'DDR Overview',
    dImg: getCellImage('DDR Overview', false),
    mImg: getCellImage('DDR Overview', true),
  };

  const dSortCells = ['tumor', 'dendritic', 'b', 't'];
  const mSortCells = ['b', 'tumor', 't', 'dendritic'];
  const sortCells = (nvLinks: any, isMobile: boolean) => {
    const cells = isMobile ? mSortCells : dSortCells;
    nvLinks.sort((a: any, b: any) => (
      cells.findIndex((item) => item === getCellPrefix(a.label))
      - cells.findIndex((item) => item === getCellPrefix(b.label))
    ));

    if (isMobile) {
      nvLinks.push(ddrOverview);
    }
    return nvLinks;
  };

  const renderCells = (nvLinks: any, isMobile: boolean) => {
    const retItems: any = [];
    const sortedLink = sortCells(nvLinks, isMobile);
    if (isMobile) {
      sortedLink.forEach((item: any) => {
        retItems.push(
          <li>
            <Link to={item.url}>
              <span>
                <img src={item.mImg} alt={item.label} />
              </span>
              <strong>{item.label}</strong>
            </Link>
          </li>,
        );
      });
    } else {
      sortedLink.forEach((item: any, index: number) => {
        retItems.push(
          <li className={`d-cell-0${index + 1}`}>
            <Link to={item.url}>
              <div className="cell-icon">
                <img src={item.dImg} alt={item.label} />
              </div>
              <div className="cell-link">
                <strong>{item.label}</strong>
                <span>
                  <img src={cellCircleImg} alt="Cell" />
                </span>
              </div>
            </Link>
            {getCellPrefix(item.label).includes('tumor') && (
              <div className="sub-inner-link">
                <Link to={ddrOverview.url}>
                  <div className="cell-icon">
                    <img src={ddrOverview.dImg} alt={ddrOverview.label} />
                  </div>
                  <div className="cell-link">
                    <span>
                      <img src={cellCircleImg} alt="Cell" />
                    </span>
                    <strong>{ddrOverview.label}</strong>
                  </div>
                </Link>
              </div>
            )}
          </li>,
        );
      });
    }
    return retItems;
  };
  // Add DDR Overview link
  // navLinks.push({
  //  url: '/cell-pathway/ddr-overview',
  //  label: 'DDR Overview',
  // });

  return (
    <MainContentWrapper wrapperClass="cell-pathway-landing" addBodyHomeClass>
      <div className="inner-container">
        <div className="container">
          <div className="grid-cell-pathway">
            <Welcome
              title="<strong>Browse by Cell Pathway</strong>"
              description=""
              containerCssClass="cell-welcome"
            />
            <div className="cell-pathway-actions">
              <ul className="d-cell-pathway-actions">
                {renderCells(navLinks, false)}
              </ul>
            </div>
            <ul className="m-cell-pathway-actions">
              {renderCells(navLinks, true)}
            </ul>
          </div>
        </div>
      </div>
    </MainContentWrapper>
  );
}

export default CellPathway;
